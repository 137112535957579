import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";

var isDebug = false
var apiURL = ""

if (isDebug) {
  apiURL = "http://localhost:8080/api/"
}
else {
  apiURL = "http://ise.zerotier.tig.pw:8000/api/"
}

Vue.use(Vuex)
/**
 * Call this function from inside actions section to load item from url and commit it to given commit name
 * @param {*} context Current Context
 * @param {*} commitName name of commit
 * @param {*} url url to retrieve object
 */
async function loadGenericList(context,commitName,url){
  return axios.get(url).then((res)=>{
    console.log(commitName)
    context.commit(commitName, res.data.results)
  }).catch((error)=>{
    console.log("error: " + error.response.data)
  })
}
/** This function returns the id of the item
    eg. getting "123" out of "http://somewebsite/api/somedata/123/"
    @author Tiger Kaovilai
    @param url url string of item in the format "http://somewebsite/api/somedata/123/"
    @returns itemID which will be a string identifying the object
*/
function getItemPkfromUrl(url){
  return url.match(/(\d*)\/$/)[1]
}

/**
 * Get an array containing values that can be used to set date and time to now in DateDate and DateTime fields
 * @returns [DateDate, DateTime]
 */
function getDateAndTimeNow(){
  var now = new Date();
  return [now.getFullYear() + '-' + (now.getMonth()+1) + '-' +  now.getDate(),now.getHours() + ':' + now.getMinutes()]
}

function validCommon(common, snackbar){
  if(!common.fields.FactoryName){
    snackbar("Please fill FactoryName", "red")
    return false
  }
  if(!common.fields.BusinessUnit){
    snackbar("Please fill BusinessUnit", "red")
    return false
  }
  if(!common.fields.Department){
    snackbar("Please fill Department", "red")
    return false
  }
  if(!common.fields.WorkCenter){
    snackbar("Please fill WorkCenter", "red")
    return false
  }
  if(!common.fields.TaskID){
    snackbar("Please fill TaskID","red");
    return false
  }
  if(!common.fields.EngineerRACF){
    snackbar("Please fill EngineerRACF","red");
    return false
  }
  // if(!common.fields.CITeam){
  //   snackbar("Please fill CITeam","red");
  //   return false
  // }
  return true;
}

function validWorker(worker, snackbar){
  if(!worker.fields.RACF){
    snackbar("Please fill RACF","red");
    return false
  }
  if(!worker.fields.Height){
    snackbar("Please fill Height","red");
    return false
  }
  if(worker.fields.Height > 9999.99){
    snackbar("Height cannot be greater than 9999.99","red");
    return false
  }
  if(worker.fields.Height < 0){
    snackbar("Height must be greater than 0","red");
    return false
  }
  if(!worker.fields.Weight){
    snackbar("Please fill Weight","red");
    return false
  }
  if(worker.fields.Weight > 9999.99){
    snackbar("Weight cannot be greater than 9999.99","red");
    return false
  }
  if(worker.fields.Weight < 0){
    snackbar("Weight must be greater than 0","red");
    return false
  }
  return true
}

function getLocalDate(DateDate,DateTime){
  var localDateVal = DateDate.split('-');
  var localDate = new Date();
  localDate.setFullYear(localDateVal[0])
  localDate.setMonth(localDateVal[1]-1)
  localDate.setDate(localDateVal[2])
  if(DateTime != null){
    var localTime = DateTime.split(':');
    localDate.setHours(localTime[0],localTime[1]);
  } else {
    localDate.setHours(0,0);
  }
  return localDate;
}
export default new Vuex.Store({
  state: {
    UI:{
      snackbar: false,
      snackbarText: "",
      responseDebug: "",
      appTab: null,
      triggerFactoryCard: true,
    },
    cameraCard: {
      "REBA Form": {
        imgSrcFile: null,
        imgSrc: null,
        imgFilePickerClicked: false,
      },
      "RULA Form": {
        imgSrcFile: null,
        imgSrc: null,
        imgFilePickerClicked: false,
      },
      "NIOSH": {
        imgSrcFile: null,
        imgSrc: null,
        imgFilePickerClicked: false,
      },
    },
    methods: {
      callSnackbar: null
    },
    forms: {
      Lists: {
        Factory: null,
        CITeam: null,
        Engineer: null,
        BusinessUnit: null,
        Department: null,
        WorkCenter: null,
        SubmittedFormsREBA: null,
        SubmittedFormsRULA: null,
        SubmittedFormsNIOSH: null,
        Tasks: null,
        Worker: null,
        pictureREBA: null,
        pictureRULA: null,
        pictureNIOSH: null,
      },
      Common: {
        fields: {
          FactoryName: null,
          CITeam: null,
          BusinessUnit: null,
          Department: null,
          WorkCenter: null,
          TaskID: null,
          EngineerRACF: null,
        },
        clearCommon: {
          FactoryName: null,
          CITeam: null,
          BusinessUnit: null,
          Department: null,
          WorkCenter: null,
          TaskID: null,
          EngineerRACF: null,
        },
        clearEngineer: {
          EngineerRACF: null,
        },
        clearFactory: {
          FactoryName: null,
          CITeam: null,
          BusinessUnit: null,
          Department: null,
          WorkCenter: null,
          Task: null,
        }
      },
      Worker: {
        fields: {
          RACF: null,
          Weight: null,
          Height: null,
          Date: null,
        },
        default: {
          RACF: null,
          Weight: null,
          Height: null,
          Date: null,
        }
      },
      REBA: {
        fields: {
          url: null,
          Height: null,
          Weight: null,
          DateDate: null,
          DateTime: null,
          NeckPositionAngle: 0,
          NeckTwist: false,
          NeckSideBend: false,
          TrunkAngle: 0,
          TrunkTwist: false,
          TrunkSideBend: false,
          LegAngle: 0,
          LegGeometry: 0,
          ForceLoad: 0,
          ForceLoadKg: 0,
          ForceLoadLb: 0,
          ShockOrRapidBuildUp: false,
          UpperArmAngle: 0,
          ShoulderRaised: false,
          UpperArmAbducted: false,
          ArmSupportOrLean: false,
          LowerArmAngle: 0,
          WristAngle: 0,
          WristTwist: false,
          CouplingScore: 0,
          ActivityHeldLong: false,
          ActivitySmallRangeActions: false,
          ActivityRapidLargeRange: false,
          ActivityScore: 0,
          RebaScore: -1,
        },
        default: {
          url: null,
          Height: null,
          Weight: null,
          DateDate: null,
          DateTime: null,
          NeckPositionAngle: 0,
          NeckTwist: false,
          NeckSideBend: false,
          TrunkAngle: 0,
          TrunkTwist: false,
          TrunkSideBend: false,
          LegAngle: 0,
          LegGeometry: 0,
          ForceLoad: 0,
          ForceLoadKg: 0,
          ForceLoadLb: 0,
          ShockOrRapidBuildUp: false,
          UpperArmAngle: 0,
          ShoulderRaised: false,
          UpperArmAbducted: false,
          ArmSupportOrLean: false,
          LowerArmAngle: 0,
          WristAngle: 0,
          WristTwist: false,
          CouplingScore: 0,
          ActivityHeldLong: false,
          ActivitySmallRangeActions: false,
          ActivityRapidLargeRange: false,
          ActivityScore: 0,
          RebaScore: -1,
        },
        calc: {
          table_a: [[[1, 2, 3, 4], [2, 3, 4, 5], [2, 4, 5, 6], [3, 5, 6, 7], [4, 6, 7, 8]],
                 [[1, 2, 3, 4], [3, 4, 5, 6], [4, 5, 6, 7], [5, 6, 7, 8], [6, 7, 8, 9]],
                 [[3, 3, 5, 6], [4, 5, 6, 7], [5, 6, 7, 8], [6, 7, 8, 9], [7, 8, 9, 9]]],
        table_b: [[[1, 2, 2], [1, 2, 3], [3, 4, 5], [4, 5, 5], [6, 7, 8], [7, 8, 8]],
                 [[1, 2, 3], [2, 3, 4], [4, 5, 5], [5, 6, 7], [7, 8, 8], [8, 9, 9]]],
        table_c: [[1, 1, 1, 2, 3, 3, 4, 5, 6, 7, 7, 7],
                 [1, 2, 2, 3, 4, 4, 5, 6, 6, 7, 7, 8],
                 [2, 3, 3, 3, 4, 5, 6, 7, 7, 8, 8, 8],
                 [3, 4, 4, 4, 5, 6, 7, 8, 8, 9, 9, 9],
                 [4, 4, 4, 5, 6, 7, 8, 8, 9, 9, 9, 9],
                 [6, 6, 6, 7, 8, 8, 9, 9, 10, 10, 10, 10],
                 [7, 7, 7, 8, 9, 9, 9, 10, 10, 11, 11, 11],
                 [8, 8, 8, 9, 10, 10, 10, 10, 10, 11, 11, 11],
                 [9, 9, 9, 10, 10, 10, 11, 11, 11, 12, 12, 12],
                 [10, 10, 10, 11, 11, 11, 11, 12, 12, 12, 12, 12],
                 [11, 11, 11, 11, 12, 12, 12, 12, 12, 12, 12, 12],
                 [12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12]],
        },
      },
      RULA: {
        fields: {
          url: null,
          WorkerRACF: "",
          Height: null,
          Weight: null,
          DateDate: null,
          DateTime: null,
          NeckPositionAngle: 0,
          NeckTwist: false,
          NeckSideBend: false,
          NeckTrunkLegMuscleUseScore: 0,
          NeckTrunkLegForceLoad: 0,
          NeckTrunkLegForceType: 0,
          TrunkAngle: 0,
          TrunkTwist: false,
          TrunkSideBend: false,
          ArmWristMuscleUseScore: false,
          LegSupported: false,
          NeckTrunkLegForceLoadKg: 0,
          NeckTrunkLegForceLoadLb: 0,
          ArmWristMuscleForceLoad: 0,
          ArmWristMuscleForceLoadType: 0,
          ArmWristMuscleForceLoadKg: 0,
          ArmWristMuscleForceLoadLb: 0,
          ArmRepeatedOrShock: false,
          UpperArmAngle: 0,
          ShoulderRaised: false,
          UpperArmAbducted: false,
          ArmSupportOrLean: false,
          ArmWristMuscleForceType: 0,
          LowerArmAngle: 0,
          ArmAcrossMidline: false,
          WristAngle: 0,
          WristBent: 0,
          WristTwist: false,
          EngineerID: null,
          RulaScore: -1,
        },
        default: {
          url: null,
          WorkerRACF: "",
          Height: null,
          Weight: null,
          DateDate: null,
          DateTime: null,
          NeckPositionAngle: 0,
          NeckTwist: false,
          NeckSideBend: false,
          NeckTrunkLegMuscleUseScore: 0,
          NeckTrunkLegForceLoad: 0,
          NeckTrunkLegForceType: 0,
          TrunkAngle: 0,
          TrunkTwist: false,
          TrunkSideBend: false,
          ArmWristMuscleUseScore: false,
          LegSupported: false,
          NeckTrunkLegForceLoadKg: 0,
          NeckTrunkLegForceLoadLb: 0,
          ArmWristMuscleForceLoad: 0,
          ArmWristMuscleForceLoadType: 0,
          ArmWristMuscleForceLoadKg: 0,
          ArmWristMuscleForceLoadLb: 0,
          ArmRepeatedOrShock: false,
          UpperArmAngle: 0,
          ShoulderRaised: false,
          UpperArmAbducted: false,
          ArmSupportOrLean: false,
          ArmWristMuscleForceType: 0,
          LowerArmAngle: 0,
          ArmAcrossMidline: false,
          WristAngle: 0,
          WristBent: 0,
          WristTwist: false,
          EngineerID: null,
          RulaScore: -1,
        },
        calc: {
          table_a: [[[1, 2, 3, 4], [2, 3, 4, 5], [2, 4, 5, 6], [3, 5, 6, 7], [4, 6, 7, 8]],
                 [[1, 2, 3, 4], [3, 4, 5, 6], [4, 5, 6, 7], [5, 6, 7, 8], [6, 7, 8, 9]],
                 [[3, 3, 5, 6], [4, 5, 6, 7], [5, 6, 7, 8], [6, 7, 8, 9], [7, 8, 9, 9]]],
        table_b: [[[1, 2, 2], [1, 2, 3], [3, 4, 5], [4, 5, 5], [6, 7, 8], [7, 8, 8]],
                 [[1, 2, 3], [2, 3, 4], [4, 5, 5], [5, 6, 7], [7, 8, 8], [8, 9, 9]]],
        table_c: [[1, 1, 1, 2, 3, 3, 4, 5, 6, 7, 7, 7],
                 [1, 2, 2, 3, 4, 4, 5, 6, 6, 7, 7, 8],
                 [2, 3, 3, 3, 4, 5, 6, 7, 7, 8, 8, 8],
                 [3, 4, 4, 4, 5, 6, 7, 8, 8, 9, 9, 9],
                 [4, 4, 4, 5, 6, 7, 8, 8, 9, 9, 9, 9],
                 [6, 6, 6, 7, 8, 8, 9, 9, 10, 10, 10, 10],
                 [7, 7, 7, 8, 9, 9, 9, 10, 10, 11, 11, 11],
                 [8, 8, 8, 9, 10, 10, 10, 10, 10, 11, 11, 11],
                 [9, 9, 9, 10, 10, 10, 11, 11, 11, 12, 12, 12],
                 [10, 10, 10, 11, 11, 11, 11, 12, 12, 12, 12, 12],
                 [11, 11, 11, 11, 12, 12, 12, 12, 12, 12, 12, 12],
                 [12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12]],
        },
      },
      NIOSH: {
        fields: {
          // get these from Worker // WorkerRACF: "",
          // Height: null,
          // Weight: null,
          url: null,
          DateDate: null,
          DateTime: null,
          HorizontalOriginDistance: 0,
          HorizontalDestinationDistance: 0,
          VerticalOriginDistance: 0,
          VerticalDestinationDistance: 0,
          AsymmetryAngle: 0,
          Load: 0,
          WorkDuration: 0,
          Frequency: 0,
          CouplingCondition: 0,
          RWLOrigin: null,
          RWLDestination: null,
          LIOrigin: null,
          LIDestination: null,
          EngineerID: null,
          CITeamID: null
        },
        default: {
          url: null,
          DateDate: null,
          DateTime: null,
          HorizontalOriginDistance: 0,
          HorizontalDestinationDistance: 0,
          VerticalOriginDistance: 0,
          VerticalDestinationDistance: 0,
          AsymmetryAngle: 0,
          Load: 0,
          WorkDuration: 0,
          Frequency: 0,
          CouplingCondition: 0,
          RWLOrigin: null,
          RWLDestination: null,
          LIOrigin: null,
          LIDestination: null,
          EngineerID: null,
          CITeamID: null
        },
        calc: {
        },
      },
    },
    limits: {
      neckAngle_max: 160,
      neckAngle_min: -50,
      trunkAngle_max: 170,
      trunkAngle_min: -65,
      legAngle_max: 135,
      legAngle_min: 0,
      upperArmAngle_max: 120,
      upperArmAngle_min: -60,
      lowerArmAngle_max: 135,
      lowerArmAngle_min: 0,
      wristAngle_max: 90,
      wristAngle_min: -90,
    },
    conversions: {
      kgToNewtons: 9.80665,
      lbToNewtons: 4.44822162
    },
  },
  getters:{
    // tabNames: state => {
    //   return state.Forms.filter(Forms => Forms.shortName);
    // },
    // forms: state => {
    //   return state.Forms.
    // },
    limits: state => {
      return state.limits
    }
  },
  mutations: {
    clearFactory: state => {
      state.forms.Common.fields.FactoryName = JSON.parse(JSON.stringify(state.forms.Common.clearFactory.FactoryName))
    },
    clearEngineer: state => {
      state.forms.Common.fields.EngineerRACF = JSON.parse(JSON.stringify(state.forms.Common.clearEngineer.EngineerRACF))
    },
    clearWorker: state => {
      state.forms.Worker.fields = JSON.parse(JSON.stringify(state.forms.Worker.default))
    },
    clearCommon: state => {
      state.forms.Common.fields = JSON.parse(JSON.stringify(state.forms.Common.clearCommon))
    },
    updateREBAurl: (state, value) => {
      state.forms.REBA.fields.url = value
    },
    updateRULAurl: (state, value) => {
      state.forms.RULA.fields.url = value
    },
    updateNIOSHurl: (state, value) => {
      state.forms.NIOSH.fields.url = value
    },
    updateUI: (state, value) => {
      state.UI = value
    },
    updateTaskID: (state, value) => {
      state.forms.Common.fields.TaskID = value
    },
    updateEngineer: (state, value) => {
      state.forms.Common.fields.EngineerRACF = value
    },
    setCameraCard: (state, value) => {
      state.cameraCard = value
    },
    /**
     * Only call this from editForm
     * 
     * This function sets file as the image source file
     * Marks file picker clicked as false
     * imgFilePickerClicked indicates if the image has been updated after an edit action
     * @param {*} state state
     * @param {*} array [formTitle, file]
     */
    setCameraCardSrcFile: (state, [formTitle, file]) => {
      state.cameraCard[formTitle]["imgSrcFile"] = file
      state.cameraCard[formTitle]["imgFilePickerClicked"] = false
    },
    clearREBA: state => {
      state.forms.REBA.fields = JSON.parse(JSON.stringify(state.forms.REBA.default))
      let [date, time] = getDateAndTimeNow()
      state.forms.REBA.fields.DateDate = date
      state.forms.REBA.fields.DateTime = time
      state.forms.Common.fields.TaskID = null
      state.UI.responseDebug = ""
    },
    clearRULA: state => {
      state.forms.RULA.fields = JSON.parse(JSON.stringify(state.forms.RULA.default))
      let [date, time] = getDateAndTimeNow()
      state.forms.RULA.fields.DateDate = date
      state.forms.RULA.fields.DateTime = time
      state.forms.Common.fields.TaskID = null
      state.UI.responseDebug = ""
    },
    clearNIOSH: state => {
      state.forms.NIOSH.fields = JSON.parse(JSON.stringify(state.forms.NIOSH.default))
      let [date, time] = getDateAndTimeNow()
      state.forms.NIOSH.fields.DateDate = date
      state.forms.NIOSH.fields.DateTime = time
      state.forms.Common.fields.TaskID = null
      state.UI.responseDebug = ""
    },
    updateREBA: (state, value) => {
      state.forms.REBA.fields = value
    },
    updateRULA: (state, value) => {
      state.forms.RULA.fields = value
    },
    updateCommon: (state, value) => {
      state.forms.Common.fields = value
    },
    updateWorker: (state, value) => {
      state.forms.Worker.fields = value
    },
    updateUIResponseDebug: (state, value) => {
      state.UI.responseDebug = value
    },
    loadFactoryList: (state, value) => {
      state.forms.Lists.Factory = value
      console.log(state.forms.Lists.Factory)
    },
    loadCITeamList: (state, value) => {
      state.forms.Lists.CITeam = value
      console.log(state.forms.Lists.CITeam)
    },
    loadEngineerList: (state, value) => {
      state.forms.Lists.Engineer = value
    },
    loadWorkerList: (state, value) => {
      state.forms.Lists.Worker = value
    },
    loadBusinessUnitList: (state, value) => {
      state.forms.Lists.BusinessUnit = value
    },
    loadDepartmentList: (state, value) => {
      state.forms.Lists.Department = value
      console.log(state.forms.Lists.Department)
    },
    loadWorkCenterList: (state, value) => {
      state.forms.Lists.WorkCenter = value
    },
    loadTasksList: (state, value) => {
      state.forms.Lists.Tasks = value
      state.UI.addNewTask = false
    },
    loadSubmittedFormsREBA: (state, value) => {
      state.forms.Lists.SubmittedFormsREBA = value
    },
    loadSubmittedFormsRULA: (state, value) => {
      state.forms.Lists.SubmittedFormsRULA = value
    },
    loadSubmittedFormsNIOSH: (state, value) => {
      state.forms.Lists.SubmittedFormsNIOSH = value
    },
    loadPictureREBA: (state, value) => {
      state.forms.Lists.pictureREBA = value
    },
    loadPictureRULA: (state, value) => {
      state.forms.Lists.pictureRULA = value
    },
    loadPictureNIOSH: (state, value) => {
      state.forms.Lists.pictureNIOSH = value
    },
    setCallSnackbar: (state, value) => {
      state.methods.callSnackbar = value
    },
    /**
     * Perform tasks necessary to accommodate change in selected engineers
     * @param {*} state 
     */
    selectedEngineerChanged: (state) => {
      state.forms.Lists.SubmittedFormsREBA = null
      state.forms.Lists.SubmittedFormsRULA = null
      state.forms.Lists.SubmittedFormsNIOSH = null
      // while(clearList.length > 0)
    },
    /**
     * Edit form function callable from individual listings in history tab
     * - Pushes you to the right form tab, and restore field values with values from the database.
     * @param {*} state current state
     * @param {*} item form item to edit
     */
    editForm: (state, item) => {
      console.log("in edit form")
      console.log(state)
      console.log(JSON.stringify(item))
      console.log(item)
      let dateISOString = item.Date
      let itemDate = new Date(dateISOString)
      if(item.url.includes("REBA")){
        state.forms.REBA.fields.url = item.url
        state.forms.REBA.fields.DateDate = itemDate.getFullYear() + '-' + (itemDate.getMonth()+1) + '-' +  itemDate.getDate();
        state.forms.REBA.fields.DateTime = itemDate.getHours() + ':' + itemDate.getMinutes();
        state.forms.REBA.fields.NeckPositionAngle = item.NeckPositionAngle
        state.forms.REBA.fields.NeckTwist = item.NeckTwist
        state.forms.REBA.fields.NeckSideBend = item.NeckSideBend
        state.forms.REBA.fields.TrunkAngle = item.TrunkPositionAngle
        state.forms.REBA.fields.TrunkTwist = item.TrunkTwist
        state.forms.REBA.fields.TrunkSideBend = item.TrunkSideBend
        state.forms.REBA.fields.LegAngle = item.LegAngle
        state.forms.REBA.fields.LegGeometry = item.LegGeometry.toString()
        state.forms.REBA.fields.ForceLoad = item.ForceLoad
        state.forms.REBA.fields.ForceLoadKg = state.forms.REBA.fields.ForceLoad/state.conversions.kgToNewtons
        state.forms.REBA.fields.ForceLoadLb = state.forms.REBA.fields.ForceLoad/state.conversions.lbToNewtons
        state.forms.REBA.fields.ShockOrRapidBuildUp = item.ShockOrRapidBuildUp
        state.forms.REBA.fields.UpperArmAngle = item.UpperArmAngle
        state.forms.REBA.fields.ShoulderRaised = item.ShoulderRaised
        state.forms.REBA.fields.UpperArmAbducted = item.UpperArmAbducted
        state.forms.REBA.fields.ArmSupportOrLean = item.ArmSupportOrLean
        state.forms.REBA.fields.LowerArmAngle = item.LowerArmAngle
        state.forms.REBA.fields.WristAngle = item.WristAngle
        state.forms.REBA.fields.WristTwist = item.WristTwist
        state.forms.REBA.fields.CouplingScore = item.CouplingScore.toString()
        state.forms.REBA.fields.ActivityScore = item.ActivityScore.toString()
        state.forms.REBA.fields.RebaScore = item.REBAScore
        state.UI.appTab = 0 //switch to REBA form
      }
      if(item.url.includes("RULA")){
        state.forms.RULA.fields.url = item.url
        state.forms.RULA.fields.DateDate = itemDate.getFullYear() + '-' + (itemDate.getMonth()+1) + '-' +  itemDate.getDate();
        state.forms.RULA.fields.DateTime = itemDate.getHours() + ':' + itemDate.getMinutes();
        state.forms.RULA.fields.NeckPositionAngle = item.NeckPositionAngle
        state.forms.RULA.fields.NeckTwist = item.NeckTwist
        state.forms.RULA.fields.NeckSideBend = item.NeckSideBend
        state.forms.RULA.fields.TrunkAngle = item.TrunkPositionAngle
        state.forms.RULA.fields.TrunkTwist = item.TrunkTwist
        state.forms.RULA.fields.TrunkSideBend = item.TrunkSideBend
        state.forms.RULA.fields.UpperArmAngle = item.UpperArmAngle
        state.forms.RULA.fields.ShoulderRaised = item.ShoulderRaised
        state.forms.RULA.fields.UpperArmAbducted = item.UpperArmAbducted
        state.forms.RULA.fields.ArmSupportOrLean = item.ArmSupportOrLean
        state.forms.RULA.fields.LowerArmAngle = item.LowerArmAngle
        state.forms.RULA.fields.ArmAcrossMidline = item.LowerArmMidlineOrSide
        state.forms.RULA.fields.WristAngle = item.WristAngle
        state.forms.RULA.fields.WristBent = item.WristBent
        state.forms.RULA.fields.WristTwist = item.WristTwist
        state.forms.RULA.fields.ArmWristMuscleUseScore = item.ArmWristMuscleUse
        state.forms.RULA.fields.ArmWristMuscleForceLoad = item.ArmWristForceLoad
        state.forms.RULA.fields.ArmWristMuscleForceLoadType = item.ArmWristForceType.toString()
        state.forms.RULA.fields.LegSupported = item.LegSupport
        state.forms.RULA.fields.NeckTrunkLegMuscleUseScore = item.NeckTrunkLegMuscleUse
        state.forms.RULA.fields.NeckTrunkLegForceLoad = item.NeckTrunkLegForceLoad
        state.forms.RULA.fields.NeckTrunkLegForceType = item.NeckTrunkLegForceType.toString()
        state.forms.RULA.fields.RulaScore = item.RULAScore
        state.UI.appTab = 1 //switch to RULA form
      }
      if(item.url.includes("NIOSH")){
        state.forms.NIOSH.fields.DateDate = itemDate.getFullYear() + '-' + (itemDate.getMonth()+1) + '-' +  itemDate.getDate();
        state.forms.NIOSH.fields.DateTime = itemDate.getHours() + ':' + itemDate.getMinutes();
        state.HorizontalOriginDistance = item.HorizontalOriginDistance
        state.HorizontalDestinationDistance = item.HorizontalDestinationDistance
        state.VerticalOriginDistance = item.VerticalOriginDistance
        state.VerticalDestinationDistance = item.VerticalDestinationDistance
        state.AsymmetryAngle = item.AsymmetryAngle
        state.Load = item.Load
        state.WorkDuration = item.WorkDuration
        state.Frequency = item.Frequency
        state.CouplingCondition = item.CouplingCondition.toString()
        state.RWLOrigin = item.RWLOrigin
        state.RWLDestination = item.RWLDestination
        state.LIOrigin = item.LIOrigin
        state.LIDestination = item.LIDestination
        state.forms.NIOSH.fields.url = item.url
        state.UI.appTab = 2
      }
      state.forms.Worker.fields.RACF = item.WorkerRACF
      state.forms.Worker.fields.Height = item.Height
      state.forms.Worker.fields.Weight = item.Weight
      // unnecessary because it is this engineer requesting // state.forms.Common.fields.EngineerRACF = item.EngineerID
    },
    triggerFactoryCard: state => {
      state.UI.triggerFactoryCard = !state.UI.triggerFactoryCard
    }
    
  },

  actions: {
    setCameraCard(context, val){
      context.commit('setCameraCard', val)
    },
    async loadFactoryList(context){
      return loadGenericList(context,'loadFactoryList',apiURL + "factories/")
    },
    async loadCITeamList(context){
      if(typeof context.state.forms.Common.fields.FactoryName == "object")
        return loadGenericList(context,'loadCITeamList',apiURL + "ciTeam/" + "?Visible=1&FactoryID=" + getItemPkfromUrl(context.state.forms.Common.fields.FactoryName.url))
    },
    async loadBusinessUnitList(context){
      if(typeof context.state.forms.Common.fields.FactoryName == "object")
        return loadGenericList(context,'loadBusinessUnitList',apiURL + "businessUnit/" + "?Visible=1&FactoryID=" + getItemPkfromUrl(context.state.forms.Common.fields.FactoryName.url))
    },
    async loadDepartmentList(context){
      if(typeof context.state.forms.Common.fields.BusinessUnit == "object")
        return loadGenericList(context,'loadDepartmentList',apiURL + "department/" + "?Visible=1&BusinessUnitID=" + getItemPkfromUrl(context.state.forms.Common.fields.BusinessUnit.url))
    },
    async loadWorkCenterList(context){
      if(typeof context.state.forms.Common.fields.Department == "object")
        return loadGenericList(context,'loadWorkCenterList',apiURL + "workCenter/" + "?Visible=1&DepartmentID=" + getItemPkfromUrl(context.state.forms.Common.fields.Department.url))
    },
    async loadTasksList(context){
      if(typeof context.state.forms.Common.fields.WorkCenter == "object")
        return loadGenericList(context,'loadTasksList',apiURL + "tasks/" + "?Visible=1&WorkCenterID=" + getItemPkfromUrl(context.state.forms.Common.fields.WorkCenter.url))
    },
    async loadEngineerList(context){
      return loadGenericList(context,'loadEngineerList',apiURL + "engineers/?Visible=1")
    },
    async loadWorkerList(context){
      return loadGenericList(context,'loadWorkerList',apiURL + "workers/?Visible=1")
    },
    async loadSubmittedForms(context){
      // let engineerItem = context.state.forms.Lists.Engineer.find(el => el.url == EngineerRACF)
      let Engineer = context.state.forms.Common.fields.EngineerRACF
      if(!Engineer)
        return //do not proceed if engineer is null
      let options = ""
      if(Engineer){
        options += "?Visible=1&EngineerID=" + getItemPkfromUrl(Engineer.url)
      }
      console.log("loading forms for Engineer RACF:" + Engineer.RACF)
      // context.state.methods.callSnackbar("loading submitted forms for EngineerRACF: " + Engineer.RACF)
      loadGenericList(context,'loadSubmittedFormsREBA',apiURL + "REBA/" + options)
      loadGenericList(context,'loadSubmittedFormsRULA',apiURL + "RULA/" + options)
      loadGenericList(context,'loadSubmittedFormsNIOSH',apiURL + "NIOSH/" + options)
    },
    clearREBA(context){
      context.commit('clearREBA')
    },
    clearRULA(context){
      context.commit('clearRULA')
    },
    clearNIOSH(context){
      context.commit('clearNIOSH')
    },
    clearEngineer(context){
      context.commit('clearEngineer')
      context.dispatch('selectedEngineerChanged')
    },
    clearWorker(context){
      context.commit('clearWorker')
    },
    clearFactory(context){
      context.commit('clearFactory')
    },
    clearAll(context){
      context.dispatch('clearREBA')
      context.dispatch('clearRULA')
      context.dispatch('clearNIOSH')
      context.dispatch('clearEngineer')
      context.dispatch('clearWorker')
      context.dispatch('clearFactory')
    },
    updateREBA(context, value){
      context.commit('updateREBA', value)
    },
    updateRULA(context, value){
      context.commit('updateRULA', value)
    },
    updateCommon(context, value){
      context.commit('updateCommon', value)
    },
    updateWorker(context, value){
      context.commit('updateWorker', value)
    },
    updateUI(context, value){
      context.commit('updateUI', value)
    },
    setCallSnackbar(context, value){
      context.commit('setCallSnackbar', value)
    },
    async editForm(context,item){
      let CITeam = null
      if(item.CITeamID != null)
        CITeam = await axios.get(item.CITeamID).catch((error)=>{
          console.log("error: " + error.response.data)
        })
      let Task = await axios.get(item.TaskID).catch((error)=>{
        console.log("error: " + error.response.data)
      })
      let WorkCenter = await axios.get(Task.data.WorkCenterID).catch((error)=>{
        console.log("error: " + error.response.data)
      })
      let Department = await axios.get(WorkCenter.data.DepartmentID).catch((error)=>{
        console.log("error: " + error.response.data)
      })
      let BusinessUnit = await axios.get(Department.data.BusinessUnitID).catch((error)=>{
        console.log("error: " + error.response.data)
      })
      let Factory = await axios.get(BusinessUnit.data.FactoryID).catch((error)=>{
        console.log("error: " + error.response.data)
      })
      let CommonCopy = context.state.forms.Common.fields
      CommonCopy.FactoryName = Factory.data
      CommonCopy.BusinessUnit = BusinessUnit.data
      CommonCopy.Department = Department.data
      CommonCopy.WorkCenter = WorkCenter.data
      CommonCopy.TaskID = Task.data
      if(CITeam == null)
        CommonCopy.CITeam = null
      else
        CommonCopy.CITeam = CITeam.data
      await context.dispatch('updateCommon', CommonCopy)
      await context.dispatch('loadBusinessUnitList')
      await context.dispatch('loadCITeamList')
      await context.dispatch('loadDepartmentList')
      await context.dispatch('loadWorkCenterList')
      await context.dispatch('loadTasksList')
      //load images
      /**
       * 
       * @param {*} pictureList context.state.forms.Lists.pictureREBA
       * @param {*} formTitle 'REBA Form'
       */
      async function loadImg(pictureList, formTitle){
          console.log(pictureList)
          console.log(item.url)
          // find picture for this form item
          let formPicObjFromList = pictureList.find(picItem => picItem.RULAFormID == item.url || picItem.REBAFormID == item.url || picItem.NIOSHFormID == item.url)
          console.log(formPicObjFromList)
          if(formPicObjFromList != undefined){
            let picInstance = await axios.get(formPicObjFromList.PictureID)
            var blob = null
            var xhr = new XMLHttpRequest()
            xhr.open("GET", picInstance.data.Picture)
            xhr.responseType = "blob"
            xhr.onload = function() 
            {
                blob = xhr.response
                context.commit('setCameraCardSrcFile',[formTitle, new File([blob],picInstance.data.Picture)])
              }
              xhr.send()
              console.log(picInstance)
            } else {
              context.commit('setCameraCardSrcFile',[formTitle, null])
          }
      }
      if(item.url.includes("REBA")){
        await loadGenericList(context,'loadPictureREBA',apiURL + "pictureREBA/")
        if(context.state.forms.Lists.pictureREBA != null){
          loadImg(context.state.forms.Lists.pictureREBA, 'REBA Form')
        }
      }
      if(item.url.includes("RULA")){
        await loadGenericList(context,'loadPictureRULA',apiURL + "pictureRULA/")
        if(context.state.forms.Lists.pictureRULA != null){
          loadImg(context.state.forms.Lists.pictureRULA, 'RULA Form')
        }
      }
      if(item.url.includes("NIOSH")){
        await loadGenericList(context,'loadPictureNIOSH',apiURL + "pictureNIOSH/")
        if(context.state.forms.Lists.pictureNIOSH != null){
          loadImg(context.state.forms.Lists.pictureNIOSH, 'NIOSH')
        }
      }
      context.commit('editForm', item)
    },
    selectedEngineerChanged(context){
      context.commit('selectedEngineerChanged')
      context.dispatch('loadSubmittedForms')
    },
    addNewTask(context, dataObj){
      // First POST new task to API
      let taskName = dataObj[0], taskDescription = dataObj[1]
      var formData = {
        "TaskName": taskName,
        "Description": taskDescription,
        "Visible": 1,
        "WorkCenterID": context.state.forms.Common.fields.WorkCenter.url
      }
      axios.post(apiURL + "tasks/",formData,{}).then((res)=>{
        console.log(res);
        // Reload TaskList
        context.dispatch('loadTasksList') //loadTasksList 100ms later
        context.commit('updateTaskID', res.data)
        context.state.methods.callSnackbar("Added task:" + res.data.Description + " " + res.data.TaskName +
        " to WorkCenter: " + context.state.forms.Common.fields.WorkCenter.Description + " "+ context.state.forms.Common.fields.WorkCenter.WorkCenterName
        )
        // Reselect task with URL from api
        // responseDebugPrint(JSON.stringify(res));
        // document.getElementById("responseDebug").replaceWith(document.createTextNode(JSON.stringify(res)))
      }).catch((error) => {
        console.log("Error");
        console.log(error.response.data);
        context.commit('updateUIResponseDebug',JSON.stringify(error.response.data))
        // responseDebugPrint(JSON.stringify(error.response.data));
        // document.getElementById("responseDebug").replaceWith(document.createTextNode(JSON.stringify(error.response.data)))
      });
    },
    triggerFactoryCard(context){
      context.commit('triggerFactoryCard')
    },
    addNewEngineer(context, RACF){
      // First POST new task to API
      var formData = {
        "RACF": RACF,
        "Visible": 1
      }
      axios.post(apiURL + "engineers/",formData,{}).then((res)=>{
        console.log(res);
        context.dispatch('loadEngineerList')
        context.commit('updateEngineer', res.data)
        context.dispatch('selectedEngineerChanged')
        context.state.methods.callSnackbar("Added engineer:" + res.data.RACF)
        // Reselect task with URL from api
        // responseDebugPrint(JSON.stringify(res));
        // document.getElementById("responseDebug").replaceWith(document.createTextNode(JSON.stringify(res)))
      }).catch((error) => {
        console.log("Error");
        console.log(error.response.data);
        context.commit('updateUIResponseDebug',JSON.stringify(error.response.data))
        // responseDebugPrint(JSON.stringify(error.response.data));
        // document.getElementById("responseDebug").replaceWith(document.createTextNode(JSON.stringify(error.response.data)))
      });
    },
    addNewWorker(context, [RACF, Height, Weight, Date]){
      // First POST new task to API
      var localDate = getLocalDate(Date)
      var formData = {
        "RACF": RACF,
        "Height": Height,
        "Weight": Weight,
        "Date": localDate.toISOString(),
        "Visible": 1,
      }
      axios.post(apiURL + "workers/",formData,{}).then((res)=>{
        console.log(res);
        context.dispatch('loadWorkerList')
        context.commit('updateWorker', res.data)
        context.state.methods.callSnackbar("Added worker:" + res.data.RACF)
        // Reselect task with URL from api
        // responseDebugPrint(JSON.stringify(res));
        // document.getElementById("responseDebug").replaceWith(document.createTextNode(JSON.stringify(res)))
      }).catch((error) => {
        console.log("Error");
        console.log(error.response.data);
        context.commit('updateUIResponseDebug',JSON.stringify(error.response.data))
        // responseDebugPrint(JSON.stringify(error.response.data));
        // document.getElementById("responseDebug").replaceWith(document.createTextNode(JSON.stringify(error.response.data)))
      });
    },
    updateThisWorker(context, [RACF, Height, Weight, Date, url]){
      // First POST new task to API
      var localDate = getLocalDate(Date)
      var formData = {
        "url": url,
        "RACF": RACF,
        "Height": Height,
        "Weight": Weight,
        "Date": localDate.toISOString(),
        "Visible": 1,
      }
      axios.put(url,formData,{}).then((res)=>{
        console.log(res);
        context.dispatch('loadWorkerList')
        context.commit('updateWorker', res.data)
        context.state.methods.callSnackbar("Updated worker:" + res.data.RACF)
        // Reselect task with URL from api
        // responseDebugPrint(JSON.stringify(res));
        // document.getElementById("responseDebug").replaceWith(document.createTextNode(JSON.stringify(res)))
      }).catch((error) => {
        console.log("Error");
        console.log(error.response.data);
        context.commit('updateUIResponseDebug',JSON.stringify(error.response.data))
        // responseDebugPrint(JSON.stringify(error.response.data));
        // document.getElementById("responseDebug").replaceWith(document.createTextNode(JSON.stringify(error.response.data)))
      });
    },
    submitREBA(context){
      console.log("save button pressed");
      if(!validCommon(context.state.forms.Common, context.state.methods.callSnackbar))
      return
      if(!validWorker(context.state.forms.Worker, context.state.methods.callSnackbar))
      return
      if(context.state.forms.REBA.fields.DateDate == null){
        context.state.methods.callSnackbar("REBA: Date empty", "red")
        return
      }
      if(context.state.forms.REBA.fields.DateTime == null){
        context.state.methods.callSnackbar("REBA: Time empty", "red")
        return
      }
      var localDate = getLocalDate(context.state.forms.REBA.fields.DateDate,context.state.forms.REBA.fields.DateTime)
      
      if(!context.state.forms.REBA.fields.LegGeometry){
        context.state.methods.callSnackbar("Please fill LegGeometry","red");
        return
      }
      if(!context.state.forms.REBA.fields.CouplingScore){
        context.state.methods.callSnackbar("Please fill CouplingScore","red");
        return
      } 
      if(!context.state.forms.REBA.fields.RebaScore){
        context.state.methods.callSnackbar("Please fill RebaScore","red");
        return
      } 
      let ciTeamUrl = ""
      if(context.state.forms.Common.fields.CITeam)
        ciTeamUrl = context.state.forms.Common.fields.CITeam.url
      let workerRACF = ""
      if(typeof context.state.forms.Worker.fields.RACF == 'string'){
        workerRACF = context.state.forms.Worker.fields.RACF
      } else {
        workerRACF = context.state.forms.Worker.fields.RACF.RACF
      }
      
      let imgForm = new FormData()
      
      let imgFile = context.state.cameraCard['REBA Form']['imgSrcFile']
      if(imgFile){
        imgForm.append('Picture',imgFile)
        imgForm.append('Description',imgFile.name)
        imgForm.append('Visible',1)
      }
      var formData = {
        "WorkerRACF": workerRACF,
        "Height": context.state.forms.Worker.fields.Height,
        "Weight": context.state.forms.Worker.fields.Weight,
        "Date": localDate.toISOString(),
        "NeckPositionAngle": context.state.forms.REBA.fields.NeckPositionAngle,
        "NeckTwist": context.state.forms.REBA.fields.NeckTwist,
        "NeckSideBend": context.state.forms.REBA.fields.NeckSideBend,
        "TrunkPositionAngle": context.state.forms.REBA.fields.TrunkAngle,
        "TrunkTwist": context.state.forms.REBA.fields.TrunkTwist,
        "TrunkSideBend": context.state.forms.REBA.fields.TrunkSideBend,
        "LegAngle": context.state.forms.REBA.fields.LegAngle,
        "LegGeometry": context.state.forms.REBA.fields.LegGeometry,
        "ForceLoad": context.state.forms.REBA.fields.ForceLoad,
        "ShockOrRapidBuildUp": context.state.forms.REBA.fields.ShockOrRapidBuildUp,
        "UpperArmAngle": context.state.forms.REBA.fields.UpperArmAngle,
        "ShoulderRaised": context.state.forms.REBA.fields.ShoulderRaised,
        "UpperArmAbducted": context.state.forms.REBA.fields.UpperArmAbducted,
        "ArmSupportOrLean": context.state.forms.REBA.fields.ArmSupportOrLean,
        "LowerArmAngle": context.state.forms.REBA.fields.LowerArmAngle,
        "WristAngle": context.state.forms.REBA.fields.WristAngle,
        "WristTwist": context.state.forms.REBA.fields.WristTwist,
        "CouplingScore": context.state.forms.REBA.fields.CouplingScore,
        "ActivityScore": context.state.forms.REBA.fields.ActivityScore,
        "REBAScore": context.state.forms.REBA.fields.RebaScore,
        "Visible": 1,
        "TaskID": context.state.forms.Common.fields.TaskID.url,
        "EngineerID": context.state.forms.Common.fields.EngineerRACF.url,
        "CITeamID": ciTeamUrl,
      };
      console.log(JSON.stringify(formData))
      if(context.state.forms.REBA.fields.url != null) //if state has url, then must be coming from editing forms, submit url)
        axios.put(context.state.forms.REBA.fields.url,formData,{}).then((res)=>{
          context.state.methods.callSnackbar("Edit successful","yellow");
          context.dispatch('loadSubmittedForms')
          let formURL = res.data.url
          //upload image if image exist otherwise do nothing
          if(imgFile){
            // only upload image if imgFilePicker has been clicked
            if(context.state.cameraCard["REBA Form"]["imgFilePickerClicked"]){
              let pictureList = context.state.forms.Lists.pictureREBA
              //if lists loaded is not empty
              if(pictureList != null){
                let formPicObjFromList = pictureList.find(picItem => picItem.REBAFormID == formURL)
                // if there exists a picture from the list associated with this form already
                if(formPicObjFromList != undefined){
                  // NOTE: due to time constraints and current database design, instead of keeping old image, we upload new image in-place
                  //make this image invisible
                  // axios.get(formPicObjFromList.PictureID).then((res)=>{
                  //   let imgObj = res.data
                  //   imgObj.Visible = 0
                  //   axios.put(imgObj.url, imgObj, {})
                  // })
                  // url is picInstance.data.Picture
                  // perform PUT to change visible to 0 for this picture
                  imgForm.append('url', formPicObjFromList.PictureID)
                  axios.put(formPicObjFromList.PictureID,imgForm).then((res)=>{
                    console.log("uploadedImage")
                    console.log(res)
                  }).catch((error)=>{
                    console.log("Picture upload error")
                    console.log(error.response.data)
                  })
                } else {
                  //list is empty, so post a new pic
                  axios.post(apiURL + "pictures/",imgForm).then((res)=>{
                    console.log("uploadedImage")
                    console.log(res)
                    let picURL = res.data.url
                    console.log(picURL);
                    //put image in pictureREBA list
                    var PicForm = {
                      "PictureID": picURL,
                      "REBAFormID": formURL
                    }
                    axios.post(apiURL + "pictureREBA/",PicForm,{}).then((res)=>{
                      console.log("linked image to REBA form")
                      console.log(res);
                    }).catch((error)=>{
                      console.log("Picture REBA linking error")
                      console.log(error.response.data)
                    })  
                  }).catch((error)=>{
                    console.log("Picture upload error")
                    console.log(error.response.data)
                  })
                }
              }
            }
          } else {
            //imgFile is null
          }
        }).catch((error) => {
          console.log("Error");
          console.log(error.response.data);
          context.commit('updateUIResponseDebug',JSON.stringify(error.response.data))
          context.state.methods.callSnackbar("Edit failed","red");
          // responseDebugPrint(JSON.stringify(error.response.data));
          // document.getElementById("responseDebug").replaceWith(document.createTextNode(JSON.stringify(error.response.data)))
        });
      else axios.post(apiURL + "REBA/",formData,{}).then((res)=>{
        console.log(res);
        // responseDebugPrint(JSON.stringify(res));
        context.commit('updateUIResponseDebug',JSON.stringify(res))
        let REBAurl = res.data.url
        context.commit('updateREBAurl', REBAurl)
        context.state.methods.callSnackbar("Submission successful","green");
        context.dispatch('loadSubmittedForms')
        // document.getElementById("responseDebug").replaceWith(document.createTextNode(JSON.stringify(res)))

        //upload image
        if(imgFile){
          axios.post(apiURL + "pictures/",imgForm).then((res)=>{
            console.log("uploadedImage")
            console.log(res)
            let picURL = res.data.url
            console.log(picURL);
            //put image in pictureREBA list
            var rebaPicForm = {
              "PictureID": picURL,
              "REBAFormID": REBAurl
            }
            axios.post(apiURL + "pictureREBA/",rebaPicForm,{}).then((res)=>{
              console.log("linked image to REBA form")
              console.log(res);
            }).catch((error)=>{
              console.log("Picture REBA linking error")
              console.log(error.response.data)
            })  
          }).catch((error)=>{
            console.log("Picture upload error")
            console.log(error.response.data)
          })
        }
      }).catch((error) => {
        console.log("Error");
        console.log(error)
        console.log(error.response.data);
        context.commit('updateUIResponseDebug',JSON.stringify(error.response.data))
        context.state.methods.callSnackbar("Submission failed","red");
        // responseDebugPrint(JSON.stringify(error.response.data));
        // document.getElementById("responseDebug").replaceWith(document.createTextNode(JSON.stringify(error.response.data)))
      });
      // function responseDebugPrint(string){

        // let txtNode = document.createTextNode(string);
        // let div = document.createElement("div");
        // div.setAttribute("id", "responseDebug");
        // div.appendChild(txtNode);
        // document.getElementById("responseDebug").replaceWith(div);
      // }
    },
    submitRULA(context){
      console.log("save button pressed");
      if(context.state.forms.RULA.fields.RulaScore == -1){
        context.state.methods.callSnackbar("Have you entered data?", "red")
        return
      }
      if(context.state.forms.RULA.fields.DateDate == null){
        context.state.methods.callSnackbar("RULA: Date empty", "red")
        return
      }
      if(context.state.forms.RULA.fields.DateTime == null){
        context.state.methods.callSnackbar("RULA: Time empty", "red")
        return
      }
      try{
        console.log(context.state.forms.RULA.fields.DateDate)
      } catch (err) {
        console.log("Date empty")
        console.log(context.state.methods.callSnackbar)
        context.state.methods.callSnackbar("Date empty")
      }
      try{
        console.log(context.state.forms.RULA.fields.DateTime)
      } catch (err) {
        console.log("Time empty")
      }
      var localDate = getLocalDate(context.state.forms.RULA.fields.DateDate,context.state.forms.RULA.fields.DateTime)
      let ciTeamUrl = ""
      if(context.state.forms.Common.fields.CITeam)
        ciTeamUrl = context.state.forms.Common.fields.CITeam.url
      let workerRACF = ""
      if(typeof context.state.forms.Worker.fields.RACF == 'string'){
        workerRACF = context.state.forms.Worker.fields.RACF
      } else {
        workerRACF = context.state.forms.Worker.fields.RACF.RACF
      }

            
      let imgForm = new FormData()
      
      let imgFile = context.state.cameraCard['RULA Form']['imgSrcFile']
      if(imgFile){
        imgForm.append('Picture',imgFile)
        imgForm.append('Description',imgFile.name)
        imgForm.append('Visible',1)
      }
      var formData = {
        "WorkerRACF": workerRACF,
        "Height": context.state.forms.Worker.fields.Height,
        "Weight": context.state.forms.Worker.fields.Weight,
        "Date": localDate.toISOString(),
        "NeckPositionAngle": context.state.forms.RULA.fields.NeckPositionAngle,
        "NeckTwist": context.state.forms.RULA.fields.NeckTwist,
        "NeckSideBend": context.state.forms.RULA.fields.NeckSideBend,
        "TrunkPositionAngle": context.state.forms.RULA.fields.TrunkAngle,
        "TrunkTwist": context.state.forms.RULA.fields.TrunkTwist,
        "TrunkSideBend": context.state.forms.RULA.fields.TrunkSideBend,
        "UpperArmAngle": context.state.forms.RULA.fields.UpperArmAngle,
        "ShoulderRaised": context.state.forms.RULA.fields.ShoulderRaised,
        "UpperArmAbducted": context.state.forms.RULA.fields.UpperArmAbducted,
        "ArmSupportOrLean": context.state.forms.RULA.fields.ArmSupportOrLean,
        "LowerArmAngle": context.state.forms.RULA.fields.LowerArmAngle,
        "LowerArmMidlineOrSide": context.state.forms.RULA.fields.ArmAcrossMidline,
        "WristAngle": context.state.forms.RULA.fields.WristAngle,
        "WristBent": context.state.forms.RULA.fields.WristBent,
        "WristTwist": context.state.forms.RULA.fields.WristTwist,
        "ArmWristMuscleUse": context.state.forms.RULA.fields.ArmWristMuscleUseScore,
        "ArmWristForceLoad": context.state.forms.RULA.fields.ArmWristMuscleForceLoad,
        "ArmWristForceType": context.state.forms.RULA.fields.ArmWristMuscleForceLoadType,
        "LegSupport": context.state.forms.RULA.fields.LegSupported,
        "NeckTrunkLegMuscleUse": context.state.forms.RULA.fields.NeckTrunkLegMuscleUseScore,
        "NeckTrunkLegForceLoad": context.state.forms.RULA.fields.NeckTrunkLegForceLoad,
        "NeckTrunkLegForceType": context.state.forms.RULA.fields.NeckTrunkLegForceType,
        "RULAScore": context.state.forms.RULA.fields.RulaScore,
        "Visible": 1,
        "TaskID": context.state.forms.Common.fields.TaskID.url,
        "EngineerID": context.state.forms.Common.fields.EngineerRACF.url,
        "CITeamID": ciTeamUrl,
        "url": context.state.forms.RULA.fields.url
      };
      console.log(JSON.stringify(formData))
      if(context.state.forms.RULA.fields.url != null) //if state has url, then must be coming from editing forms, submit url)
        axios.put(context.state.forms.RULA.fields.url,formData,{}).then((res)=>{
          console.log(res);
          // responseDebugPrint(JSON.stringify(res));
          context.commit('updateUIResponseDebug',JSON.stringify(res))
          context.state.methods.callSnackbar("Edit successful","yellow");
          context.dispatch('loadSubmittedForms')
          let formURL = res.data.url
          //upload image if image exist otherwise do nothing
          if(imgFile){
            // only upload image if imgFilePicker has been clicked
            if(context.state.cameraCard["RULA Form"]["imgFilePickerClicked"]){
              let pictureList = context.state.forms.Lists.pictureRULA
              //if lists loaded is not empty
              if(pictureList != null){
                let formPicObjFromList = pictureList.find(picItem => picItem.RULAFormID == formURL)
                // if there exists a picture from the list associated with this form already
                if(formPicObjFromList != undefined){
                  // NOTE: due to time constraints and current database design, instead of keeping old image, we upload new image in-place
                  //make this image invisible
                  // axios.get(formPicObjFromList.PictureID).then((res)=>{
                  //   let imgObj = res.data
                  //   imgObj.Visible = 0
                  //   axios.put(imgObj.url, imgObj, {})
                  // })
                  // url is picInstance.data.Picture
                  // perform PUT to change visible to 0 for this picture
                  imgForm.append('url', formPicObjFromList.PictureID)
                  axios.put(formPicObjFromList.PictureID,imgForm).then((res)=>{
                    console.log("uploadedImage")
                    console.log(res)
                  }).catch((error)=>{
                    console.log("Picture upload error")
                    console.log(error.response.data)
                  })
                } else {
                  //list is empty, so post a new pic
                  axios.post(apiURL + "pictures/",imgForm).then((res)=>{
                    console.log("uploadedImage")
                    console.log(res)
                    let picURL = res.data.url
                    console.log(picURL);
                    //put image in pictureRULA list
                    var PicForm = {
                      "PictureID": picURL,
                      "RULAFormID": formURL
                    }
                    axios.post(apiURL + "pictureRULA/",PicForm,{}).then((res)=>{
                      console.log("linked image to RULA form")
                      console.log(res);
                    }).catch((error)=>{
                      console.log("Picture RULA linking error")
                      console.log(error.response.data)
                    })  
                  }).catch((error)=>{
                    console.log("Picture upload error")
                    console.log(error.response.data)
                  })
                }
              }
            }
          } else {
            //imgFile is null
          }
        }).catch((error) => {
          console.log("Error");
          console.log(error.response.data);
          context.commit('updateUIResponseDebug',JSON.stringify(error.response.data))
          context.state.methods.callSnackbar("Edit failed","red");
          // responseDebugPrint(JSON.stringify(error.response.data));
          // document.getElementById("responseDebug").replaceWith(document.createTextNode(JSON.stringify(error.response.data)))
        });
      else axios.post(apiURL + "RULA/",formData,{}).then((res)=>{
        console.log(res);
        // responseDebugPrint(JSON.stringify(res));
        context.commit('updateUIResponseDebug',JSON.stringify(res))
        let RULAurl = res.data.url
        context.commit('updateRULAurl', RULAurl)
        context.state.methods.callSnackbar("Submission successful","green");
        context.dispatch('loadSubmittedForms')
        // document.getElementById("responseDebug").replaceWith(document.createTextNode(JSON.stringify(res)))
        
        //upload image
        if(imgFile){
          axios.post(apiURL + "pictures/",imgForm).then((res)=>{
            console.log("uploadedImage")
            console.log(res)
            let picURL = res.data.url
            console.log(picURL);
            //put image in pictureRULA list
            let PicForm = {
              "PictureID": picURL,
              "RULAFormID": RULAurl
            }
            axios.post(apiURL + "pictureRULA/",PicForm,{}).then((res)=>{
              console.log("linked image to RULA form")
              console.log(res);
            }).catch((error)=>{
              console.log("Picture RULA linking error")
              console.log(error.response.data)
            })  
          }).catch((error)=>{
            console.log("Picture upload error")
            console.log(error.response.data)
          })
        }
      }).catch((error) => {
        console.log("Error");
        console.log(error.response.data);
        context.commit('updateUIResponseDebug',JSON.stringify(error.response.data))
        context.state.methods.callSnackbar("Submission failed","red");
        // responseDebugPrint(JSON.stringify(error.response.data));
        // document.getElementById("responseDebug").replaceWith(document.createTextNode(JSON.stringify(error.response.data)))
      });
      // function responseDebugPrint(string){

        // let txtNode = document.createTextNode(string);
        // let div = document.createElement("div");
        // div.setAttribute("id", "responseDebug");
        // div.appendChild(txtNode);
        // document.getElementById("responseDebug").replaceWith(div);
      // }
    },
    submitNIOSH(context){
      console.log("save button pressed");
      if(!validCommon(context.state.forms.Common, context.state.methods.callSnackbar))
        return
      if(!validWorker(context.state.forms.Worker, context.state.methods.callSnackbar))
        return
      if(context.state.forms.NIOSH.fields.DateDate == null){
        context.state.methods.callSnackbar("NIOSH: Date empty", "red")
        return
      }
      if(context.state.forms.NIOSH.fields.DateTime == null){
        context.state.methods.callSnackbar("NIOSH: Time empty", "red")
        return
      }
      try{
        console.log(context.state.forms.NIOSH.fields.DateDate)
      } catch (err) {
        console.log("Date empty")
        console.log(context.state.methods.callSnackbar)
        context.state.methods.callSnackbar("Date empty")
      }
      try{
        console.log(context.state.forms.NIOSH.fields.DateTime)
      } catch (err) {
        console.log("Time empty")
      }
      var localDate = getLocalDate(context.state.forms.NIOSH.fields.DateDate,context.state.forms.NIOSH.fields.DateTime)
      let ciTeamUrl = ""
      if(context.state.forms.Common.fields.CITeam)
        ciTeamUrl = context.state.forms.Common.fields.CITeam.url
      let nioshFields = context.state.forms.NIOSH.fields
      if(nioshFields.HorizontalOriginDistance == 0){
        context.state.methods.callSnackbar("Horizontal distance cannot be 0")
      }
      if(nioshFields.HorizontalDestinationDistance == 0){
        context.state.methods.callSnackbar("Horizontal distance cannot be 0")
      }
      if(nioshFields.VerticalOriginDistance == 0){
        context.state.methods.callSnackbar("Vertical distance cannot be 0")
      }
      if(nioshFields.VerticalDestinationDistance == 0){
        context.state.methods.callSnackbar("Vertical distance cannot be 0")
      }


      if(nioshFields.RWLOrigin == null){
        context.state.methods.callSnackbar("You have not entered data")
        return
      }
      if(nioshFields.RWLDestination == null){
        context.state.methods.callSnackbar("You have not entered data")
        return
      }
      let workerRACF = ""
      if(typeof context.state.forms.Worker.fields.RACF == 'string'){
        workerRACF = context.state.forms.Worker.fields.RACF
      } else {
        workerRACF = context.state.forms.Worker.fields.RACF.RACF
      }
            
      let imgForm = new FormData()
      
      let imgFile = context.state.cameraCard['NIOSH']['imgSrcFile']
      if(imgFile){
        imgForm.append('Picture',imgFile)
        imgForm.append('Description',imgFile.name)
        imgForm.append('Visible',1)
      }
      var formData = {
        "WorkerRACF": workerRACF,
        "Height": context.state.forms.Worker.fields.Height,
        "Weight": context.state.forms.Worker.fields.Weight,
        "Date": localDate.toISOString(),
        "HorizontalOriginDistance": nioshFields.HorizontalOriginDistance,
        "HorizontalDestinationDistance": nioshFields.HorizontalDestinationDistance,
        "VerticalOriginDistance": nioshFields.VerticalOriginDistance,
        "VerticalDestinationDistance": nioshFields.VerticalDestinationDistance,
        "AsymmetryAngle": nioshFields.AsymmetryAngle,
        "Load": nioshFields.Load,
        "WorkDuration": nioshFields.WorkDuration,
        "Frequency": nioshFields.Frequency,
        "CouplingCondition": nioshFields.CouplingCondition,
        "RWLOrigin": nioshFields.RWLOrigin,
        "RWLDestination": nioshFields.RWLDestination,
        "LIOrigin": nioshFields.LIOrigin,
        "LIDestination": nioshFields.LIDestination,
        "Visible": 1,
        "TaskID": context.state.forms.Common.fields.TaskID.url,
        "EngineerID": context.state.forms.Common.fields.EngineerRACF.url,
        "CITeamID": ciTeamUrl,
        "url": context.state.forms.NIOSH.fields.url
      };
      console.log(JSON.stringify(formData))
      if(context.state.forms.NIOSH.fields.url != null) //if state has url, then must be coming from editing forms, submit url)
        axios.put(context.state.forms.NIOSH.fields.url,formData,{}).then((res)=>{
          console.log(res);
          context.commit('updateUIResponseDebug',JSON.stringify(res))
          context.state.methods.callSnackbar("Edit successful","yellow");
          context.dispatch('loadSubmittedForms')
          let formURL = res.data.url
          //upload image if image exist otherwise do nothing
          if(imgFile){
            // only upload image if imgFilePicker has been clicked
            if(context.state.cameraCard["NIOSH"]["imgFilePickerClicked"]){
              let pictureList = context.state.forms.Lists.pictureNIOSH
              //if lists loaded is not empty
              if(pictureList != null){
                let formPicObjFromList = pictureList.find(picItem => picItem.NIOSHFormID == formURL)
                // if there exists a picture from the list associated with this form already
                if(formPicObjFromList != undefined){
                  // NOTE: due to time constraints and current database design, instead of keeping old image, we upload new image in-place
                  //make this image invisible
                  // axios.get(formPicObjFromList.PictureID).then((res)=>{
                  //   let imgObj = res.data
                  //   imgObj.Visible = 0
                  //   axios.put(imgObj.url, imgObj, {})
                  // })
                  // url is picInstance.data.Picture
                  // perform PUT to change visible to 0 for this picture
                  imgForm.append('url', formPicObjFromList.PictureID)
                  axios.put(formPicObjFromList.PictureID,imgForm).then((res)=>{
                    console.log("uploadedImage")
                    console.log(res)
                  }).catch((error)=>{
                    console.log("Picture upload error")
                    console.log(error.response.data)
                  })
                } else {
                  //list is empty, so post a new pic
                  axios.post(apiURL + "pictures/",imgForm).then((res)=>{
                    console.log("uploadedImage")
                    console.log(res)
                    let picURL = res.data.url
                    console.log(picURL);
                    //put image in pictureNIOSH list
                    var PicForm = {
                      "PictureID": picURL,
                      "NIOSHFormID": formURL
                    }
                    axios.post(apiURL + "pictureNIOSH/",PicForm,{}).then((res)=>{
                      console.log("linked image to NIOSH form")
                      console.log(res);
                    }).catch((error)=>{
                      console.log("Picture NIOSH linking error")
                      console.log(error.response.data)
                    })  
                  }).catch((error)=>{
                    console.log("Picture upload error")
                    console.log(error.response.data)
                  })
                }
              }
            }
          } else {
            //imgFile is null
          }
        }).catch((error) => {
          console.log("Error");
          console.log(error.response.data);
          context.commit('updateUIResponseDebug',JSON.stringify(error.response.data))
          context.state.methods.callSnackbar("Edit failed","red");
        });
      else axios.post(apiURL + "NIOSH/",formData,{}).then((res)=>{
        console.log(res);
        // responseDebugPrint(JSON.stringify(res));
        context.commit('updateUIResponseDebug',JSON.stringify(res))
        let NIOSHurl = res.data.url
        context.commit('updateNIOSHurl', NIOSHurl)
        context.state.methods.callSnackbar("Submission successful","green");
        context.dispatch('loadSubmittedForms')
        
        //upload image
        if(imgFile){
          axios.post(apiURL + "pictures/",imgForm).then((res)=>{
            console.log("uploadedImage")
            console.log(res)
            let picURL = res.data.url
            console.log(picURL);
            //put image in pictureREBA list
            let PicForm = {
              "PictureID": picURL,
              "NIOSHFormID": NIOSHurl
            }
            axios.post(apiURL + "pictureNIOSH/",PicForm,{}).then((res)=>{
              console.log("linked image to NIOSH form")
              console.log(res);
            }).catch((error)=>{
              console.log("Picture NIOSH linking error")
              console.log(error.response.data)
            })  
          }).catch((error)=>{
            console.log("Picture upload error")
            console.log(error.response.data)
          })
        }
      }).catch((error) => {
        console.log("Error");
        console.log(error.response.data);
        context.commit('updateUIResponseDebug',JSON.stringify(error.response.data))
        context.state.methods.callSnackbar("Submission failed","red");
      });
    },
  },
  modules: {}
})
