<template>
  <v-card>
    <v-card-title>
      {{ title }} Camera View
      <v-spacer />
      <v-btn icon v-show="!cameraViewCollapsed" @click="toggleCameraView()"><v-icon>mdi-window-minimize</v-icon></v-btn>        
      <v-btn icon v-show="cameraViewCollapsed" @click="toggleCameraView()"
        ><v-icon>mdi-window-maximize</v-icon></v-btn>
    </v-card-title>
    <v-card-subtitle>View photo reference while filling out the form</v-card-subtitle>
    <v-expand-transition>
      <div v-show="!cameraViewCollapsed">
        <v-row>
          <v-col class="d-flex justify-center"><v-checkbox label="Worker Facing Left" @change="updateAngleText" v-model="faceLeft"></v-checkbox></v-col>
          <v-col class="d-flex justify-center" style="align-items: center;"><v-btn @click="rotateImg()">Rotate 90&deg;</v-btn></v-col>
        </v-row>
        <v-card-text>
          <v-file-input
            accept="image/png, image/jpeg, image/bmp"
            prepend-icon="mdi-camera"
            label="Input Image"
            @click="imgFilePickerClicked()"
            v-model="cameraCard[title]['imgSrcFile']"
          ></v-file-input>
          <div
            :id="'pixicontainer-'+this.title"
            style="max-width: 1000px;"
          ></div>
          <v-row>
            <v-col cols="12" sm="8">
              <v-color-picker
                  style="overflow-y: hidden;"
                  width="100%"
                  :swatches="[
                      ['#FF0000'],
                      ['#FFFF00'],
                      ['#00FF00'],
                      ['#00FFFF'],
                      ['#0000FF'],
                    ]"
                  v-model="inkColor"            
                  v-on:click="mountPixi()"
                  v-on:input="updateNodeColor()"
                  show-swatches
                  hide-canvas
                  hide-sliders
                  hide-inputs
                  hide-mode-switch
                  class="ma-4"
                  dot-size="30">
                </v-color-picker>
              </v-col>
            <v-col class="d-flex justify-center" style="align-items: center;"><v-btn @click="resetCircles()">Reset Node Positions</v-btn></v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn icon @click="removeImg()"><v-icon>mdi-delete</v-icon></v-btn>
          <v-spacer />
          <v-btn v-if="loadAngleFunction" @click="loadAngleFunction()">Use Angle Values</v-btn>
        </v-card-actions>
        <v-snackbar v-model="snackbar" :color="snackbarColor"
          >{{ snackbarText
          }}<template v-slot:action="{ attrs }"
            ><v-btn text v-bind="attrs" @click="snackbar = false"
              >Close</v-btn>
          </template>
        </v-snackbar>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>

class Node {
  constructor(name) {
    this.name = name;
    this.x = 0;
    this.y = 0;
  }
    isBelowLine(centerNode, line) {
      return this.y - centerNode.y > line.slope() * (this.x - centerNode.x)
  }

  updateColor(newColor, app) {
    var gr = new PIXI.Graphics();  
              gr.beginFill(parseInt("0x"+newColor.substr(1,6)));
              gr.lineStyle(5, 0xFFFFFF, 1);
              gr.drawCircle(30, 30, 30);
              gr.endFill();

    var texture = app.renderer.generateTexture(gr);

    this.circle.texture = texture;
  }
}

class Line {
  constructor(startNode, endNode) {
    this.startNode = startNode;
    this.endNode = endNode;
  }
    theta() {
      return Math.atan(this.slope())
      }

    slope() {
      let rise = this.endNode.y - this.startNode.y;
      let run = this.endNode.x - this.startNode.x;

      return rise/run
    }
  }

class Angle {
  constructor(nodes, lines, invertAngle = false, reverseAngle = false) {
    this.nodes = nodes;
    this.lines = lines;
    this.invertAngle = invertAngle;
    this.reverseAngle = reverseAngle;
  }

  getAngle(faceLeft) {
    let angle = Math.abs(this.lines[0].theta() - this.lines[1].theta())*(180/Math.PI)
  
    if (((this.nodes[0].x - this.nodes[1].x < 0 && this.nodes[2].x - this.nodes[1].x < 0)
        || (this.nodes[0].x - this.nodes[1].x > 0 && this.nodes[2].x - this.nodes[1].x > 0))
        ^ this.reverseAngle){
          angle = 180 - angle
        }

    if (this.nodes[0].isBelowLine(this.nodes[1], this.lines[1]) ^ this.lines[1].slope() < 0 ^ faceLeft)
    {
      angle = angle * -1
    }

    if (this.invertAngle){
      angle = angle * -1
    }

    return angle
  }
}

import * as PIXI from 'pixi.js'
let logo = require('../assets/logo.png');

export default {
  props: {
    title: String,
    loadAngleFunction: Function,
  },
  data: () => ({
    cameraViewCollapsed: true,
    cameraInited: false,
    cameraDevices: [],
    selectedCamera: "",
    snackbar: false,
    snackbarText: "Hi",
    snackbarColor: null,
    inkColor: null,
    imgSrc: null,
    faceLeft: false,
    screenWidth: 0,
    screenHeight: 0,
    bodyPartObjects: [],
    circleSize: 0.75,
    app: null,
    lineGraphics: null,
    head: null,
    shoulder: null,
    hips: null,
    knee: null,
    foot: null,
    elbow: null,
    hand: null,
    wrist: null,
    bodyDict: {},
    bodyAngleDict: {},
    prevImgSrcFile: null,
    updateAngleText: null,
  }),
  computed: {
    cameraCard: {
      get(){
        return this.$store.state.cameraCard
      },
      set(val){
        this.$store.dispatch('setCameraCard', val)
      }
    },
    thisCameraCard: {
        get(){
          return this.$store.state.cameraCard[this.title]
        },
    },
  },
  watch:{
    thisCameraCard: {
      handler: function(val, oldval) {
        console.log("thiscameracard changed!")
        console.log([val, oldval])
        if(this.prevImgSrcFile == null || this.prevImgSrcFile != val.imgSrcFile){
          this.prevImgSrcFile = val.imgSrcFile
          this.imgSrcFileChanged()
        }
      },
      deep: true
    },
  },
  methods: {
    // removeChildren() {
    //   // for (var i = this.app.stage.children.length - 1; i >= 0; i--) {
    //   //   this.app.stage.removeChild(this.app.stage.children[i]);
    //   //   } 

    // },
    /**
     * Make a note that file picker has been clicked so we know that image is edited
     */
    imgFilePickerClicked(){
      this.cameraCard[this.title]['imgFilePickerClicked'] = true
    },
    rotateImg() {
      let pixiContainer = document.getElementById("pixicontainer-"+this.title);
      let transformStr = pixiContainer.style.transform;
      let degrees = 90;

      if (transformStr){
        transformStr = transformStr.split("(")[1];
        degrees = parseInt(transformStr.split("deg")[0]);

        if (degrees < 270 && degrees >= 0) {
          degrees = degrees + 90;
        } else {
          degrees = 0;
        } 
      } 
      let translateStr = ''

      if (degrees == 90) {
        translateStr = "translate(0, -100%)";
      } else if (degrees == 180) {
        translateStr = "translate(-100%, -100%)"
      } else if (degrees == 270) {
        translateStr = "translate(-100%, 0)"
      }

      pixiContainer.style.transform = "rotate(" + degrees + "deg) " + translateStr;
      this.app.view.style.transform= "rotate(-" + degrees + "deg)";
      //app.view.style.transform= "rotate(0deg)";

    },
    updateNodeColor() {
      for (const key in this.bodyDict) {
        this.bodyDict[key].updateColor(this.inkColor.hex, this.app);
      }
    },
    removeImg() {
      this.imgSrc = null;
      this.cameraCard[this.title]["imgSrcFile"] = null;
      this.imgFilePickerClicked()
      this.screenWidth = 0;
      this.screenHeight = 0;
      //this.removeChildren();
      this.cameraCard[this.title]["imgSrc"] = null
      this.mountPixi();
    },
    callSnackbar(text, color) {
      this.snackbar = true;
      this.snackbarText = text;
      this.snackbarColor = color;
      return;
    },
    toggleCameraView() {
      this.cameraViewCollapsed = !this.cameraViewCollapsed;
    },
    setImgSrc(imgSrc) {
      this.imgSrc = imgSrc;
      console.log("imgSrc")
      console.log(imgSrc)
      let pixiContainer = document.getElementById("pixicontainer-"+this.title);

      pixiContainer.style.width = '100%';
      pixiContainer.style.backgroundSize = 'contain';
      pixiContainer.style.backgroundImage = "url('"+this.imgSrc+"')";
      pixiContainer.style.transformOrigin = "top left";
      pixiContainer.style.whiteSpace = "nowrap";
      pixiContainer.style.display = "block";
      this.app.view.style.width = "100%"; 
      this.app.view.style.zIndex = "inherit"; 
      //pixiContainer.style = pixiContainer.style + "; width:100%; background-size: 100% 100%; background-image: url('"+this.imgSrc+"'); transform: rotate(0deg);"
      // this.app.view.parentElement.style = this.app.view.parentElement + "; transform: rotate(90deg);"
      
    },
    imgSrcFileChanged() {
      console.log('imgsrcfilechanged!')
      let imgReader = new FileReader();
      let page = this;

      imgReader.addEventListener("load", function(){
        imgReader.removeEventListener("load", null);
        page.setImgSrc(imgReader.result);
        let sizeCap = 600
        page.screenWidth = sizeCap
        page.screenHeight = sizeCap
        page.mountPixi()
        
      }, false);
      let imgSrcFile = this.cameraCard[this.title]["imgSrcFile"]
      if(imgSrcFile){
        imgReader.readAsDataURL(imgSrcFile);
      }
    },
    mountPixi() {
      console.log("mountPixi");
      let page = this;
      
      let pixiContainer = document.getElementById("pixicontainer-"+page.title);
      //if there exists no img src and this function is called, remove pixi app
      if(this.cameraCard[this.title]["imgSrcFile"] == null){
        pixiContainer.style.display = "none"
        return
      } else {
        pixiContainer.style.display = "block"
      }
      page.app.view.height = page.screenHeight;
      page.app.view.width = page.screenWidth;
      page.app.screen.width = page.app.view.width;
      page.app.screen.height = page.app.view.height;

      if (page.bodyPartObjects.length > 0 ) {
        return;
      }
      if(pixiContainer.hasChildNodes()) pixiContainer.removeChild(pixiContainer.children[0]);
      pixiContainer.appendChild(page.app.view);
      page.lineGraphics.clear();
      page.app.stage.addChild(page.lineGraphics);
      
      //remove any existing lists and dicts 
      for (const key in page.bodyDict) {
        page.bodyDict[key].x = 0;
        page.bodyDict[key].y = 0;
      }

      // Put image in background
      // const bg = PIXI.Sprite.fromImage(this.imgSrcFile)
      // this.app.stage.addChild(bg)

      // create a texture from an image path
      const texture = PIXI.Texture.from(logo);
      // PIXI.Texture.from(PIXI.Resource.)

      // Scale mode for pixelation
      texture.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST;

      let bodyParts = [
        "Head",
        "Shoulder",
        "Hips",
        "Knee",
        "Foot",
        "Elbow",
        "Wrist",
        "Hand",
      ]

      if (page.imgSrc) {
        for (let i = 0; i < bodyParts.length; i++) {
            // createBunny(
            //     Math.floor(Math.random() * page.app.screen.width),
            //     Math.floor(Math.random() * page.app.screen.height),
            // );
            page.bodyPartObjects.push(
              createCircle(
                  // Math.floor(Math.random() * page.app.screen.width),
                  page.app.screen.width * 0.01 * i + page.app.screen.width * 0.05,
                  // Math.floor(Math.random() * page.app.screen.height),
                  page.app.screen.height * 0.1 * i + page.app.screen.height * 0.05,
                  bodyParts[i]
                )
            )
            if (i >= 5) {
              //set elbow, wrist and hand nodes to same vertical distance as shoulder node
              page.bodyPartObjects[i].x = page.bodyPartObjects[1].x + 30 * i
              page.bodyDict[bodyParts[i]].x = page.bodyPartObjects[i].x
              page.bodyPartObjects[i].y = page.bodyPartObjects[1].y
              page.bodyDict[bodyParts[i]].y = page.bodyPartObjects[1].y
            }
        }
      }

      function createCircle(x, y, i) {
        var gr = new PIXI.Graphics();  
              gr.beginFill(parseInt("0x"+page.inkColor.hex.substr(1,6)));
              gr.lineStyle(5, 0xFFFFFF, 1);
              gr.drawCircle(30, 30, 30);
              gr.endFill();
        var textDescription = new PIXI.Text(i,{fontFamily : 'Arial', fontSize: 24, fill : 0xFFFFFF, align : 'center', strokeThickness: 5, stroke:0x000000});

        var texture = page.app.renderer.generateTexture(gr);
        var circle = new PIXI.Sprite(texture);
        circle.interactive = true;
        circle.buttonMode =true;
        circle.anchor.set(.5);
        circle.scale.set(page.circleSize);
        circle.alpha = 0.75;
        circle
            .on('pointerdown', onDragStart)
            .on('pointerup', onDragEnd)
            .on('pointerupoutside', onDragEnd)
            .on('pointermove', onDragMove);
        circle.x = x;
        circle.y = y;
        page.bodyDict[i].x = x;
        page.bodyDict[i].y = y;
        page.bodyDict[i].circle = circle;

        circle.addChild(textDescription);
        page.app.stage.addChild(circle);
        return(circle);
      }

      function drawLines() { 
        //connect head, shoulder, hips, knee, foot
        //connect elbow, wrist, hand
        for (const key in page.bodyAngleDict) {
          for (var i = 0; i < page.bodyAngleDict[key].lines.length; i++){
            page.lineGraphics.lineStyle(3, 0xFFDE00)
              .moveTo(page.bodyAngleDict[key].lines[i].startNode.x, page.bodyAngleDict[key].lines[i].startNode.y)
              .lineTo(page.bodyAngleDict[key].lines[i].endNode.x, page.bodyAngleDict[key].lines[i].endNode.y)            
          }
        }
      }

      function onDragStart(event) {
          // store a reference to the data
          // the reason for this is because of multitouch
          // we want to track the movement of this particular touch
          this.data = event.data;
          this.alpha = 0.75;
          this.dragging = true;
      }

      function onDragEnd() {
          this.alpha = 0.75;
          this.dragging = false;
          // // set the interaction data to null
          this.data = null;
          page.lineGraphics.clear();
          page.bodyDict[this.children[0].text.split(":")[0]].x = this.x
          page.bodyDict[this.children[0].text.split(":")[0]].y = this.y

          drawLines()
          updateAngleText()
          // console.log("NeckAngle: " + bodyAngleDict["neckAngle"].getAngle())
          // console.log("trunkAngle: " + bodyAngleDict["trunkAngle"].getAngle())
          // console.log("legAngle: " + bodyAngleDict["legAngle"].getAngle())
          // console.log("upperArmAngle: " + bodyAngleDict["upperArmAngle"].getAngle())
          // console.log("lowerArmAngle: " + bodyAngleDict["lowerArmAngle"].getAngle())
          // console.log("wristAngle: " + bodyAngleDict["wristAngle"].getAngle())
          
          page.cameraCard[page.title]["dict"] = page.bodyAngleDict
          page.cameraCard[page.title]["faceLeft"] = page.faceLeft

          // console.log("")
        
      }

      function onDragMove() {
          if (this.dragging) {
              const newPosition = this.data.getLocalPosition(this.parent);
              this.x = newPosition.x;
              this.y = newPosition.y;
          }
      }

      function updateAngleText() {
        page.cameraCard[page.title]["faceLeft"] = page.faceLeft
        for (const [key, angle] of Object.entries(page.bodyAngleDict)) {          
          let centralNode = angle.nodes[1]
          let angleVal = page.bodyAngleDict[key].getAngle(page.faceLeft)

          for (let i = 0; i < page.bodyPartObjects.length; i++) {
            if (page.bodyPartObjects[i].getChildAt(0).text.includes(centralNode.name) && (!centralNode.name.includes("Shoulder"))) {
               page.bodyPartObjects[i].getChildAt(0).text = centralNode.name + ": " + (angleVal).toFixed(0) + '\u00B0'
            } else {
              let neckAngleText = "Shoulder: \n Neck: " + (page.bodyAngleDict["neckAngle"].getAngle(page.faceLeft)).toFixed(0) + '\u00B0'
              let upperArmAngleText = "Arm: " + (page.bodyAngleDict["upperArmAngle"].getAngle(page.faceLeft)).toFixed(0) + '\u00B0'
              let shoulderText = neckAngleText + "\n" + upperArmAngleText              
              if (page.bodyPartObjects[i].getChildAt(0).text.includes("Shoulder")) {
                page.bodyPartObjects[i].getChildAt(0).text = shoulderText
              }
            }
          }
        }
      }
      page.updateAngleText = updateAngleText

    },

    resetCircles() {
      this.lineGraphics.clear();

      for (let i = 0; i < 5; i++) {
        this.bodyDict[this.bodyPartObjects[i].children[0].text.split(":")[0]].x = this.screenWidth * 0.01 * i + this.screenWidth * 0.05
        this.bodyDict[this.bodyPartObjects[i].children[0].text.split(":")[0]].y = this.screenHeight * 0.1 * i + this.screenHeight * 0.05
        this.bodyPartObjects[i].x = this.screenWidth * 0.01 * i + this.screenWidth * 0.05
        this.bodyPartObjects[i].y = this.screenHeight * 0.1 * i + this.screenHeight * 0.05
      }

      for (let i = 5; i < 8; i++) {
        this.bodyDict[this.bodyPartObjects[i].children[0].text.split(":")[0]].x = this.bodyDict[this.bodyPartObjects[1].children[0].text.split(":")[0]].x + 30 * i
        this.bodyDict[this.bodyPartObjects[i].children[0].text.split(":")[0]].y = this.bodyDict[this.bodyPartObjects[1].children[0].text.split(":")[0]].y
        this.bodyPartObjects[i].x = this.bodyDict[this.bodyPartObjects[1].children[0].text.split(":")[0]].x + 30 * i
        this.bodyPartObjects[i].y = this.bodyDict[this.bodyPartObjects[1].children[0].text.split(":")[0]].y
      }      
    },
  },
  mounted() {
    console.log("camcard mounted")
    this.app = new PIXI.Application({backgroundAlpha: 0})
    this.lineGraphics = new PIXI.Graphics()
    this.head = new Node('Head')
    this.shoulder = new Node('Shoulder')
    this.hips = new Node('Hips')
    this.knee = new Node('Knee')
    this.foot = new Node('Foot')
    this.elbow = new Node('Elbow')
    this.hand = new Node('Hand')
    this.wrist = new Node('Wrist')
    this.bodyDict = {
      'Head': this.head,
      'Shoulder': this.shoulder,
      'Hips': this.hips,
      'Knee': this.knee,
      'Foot': this.foot,
      'Elbow': this.elbow,
      'Wrist': this.wrist,
      'Hand': this.hand,
    },
    this.bodyAngleDict = {
      "neckAngle": new Angle([this.head, this.shoulder, this.hips], [new Line(this.head, this.shoulder), new Line(this.hips, this.shoulder)]), 
      "trunkAngle": new Angle([this.shoulder, this.hips, this.knee],[new Line(this.shoulder, this.hips), new Line(this.hips, this.knee)]),
      "legAngle": new Angle([this.foot, this.knee, this.hips], [new Line(this.knee, this.foot), new Line(this.hips, this.knee)], true),
      "upperArmAngle": new Angle([this.elbow, this.shoulder, this.hips], [new Line(this.shoulder, this.elbow), new Line(this.shoulder, this.hips)], false, true),
      "lowerArmAngle": new Angle([this.wrist, this.elbow, this.shoulder], [new Line(this.elbow, this.wrist), new Line(this.shoulder, this.elbow)]),
      "wristAngle": new Angle([this.hand, this.wrist, this.elbow], [new Line(this.wrist, this.hand), new Line(this.elbow, this.wrist)])
    }
  },
  updated(){

  }
};


</script>

<style>
</style>