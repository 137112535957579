<template>
  <v-tab-item>
    <v-container fluid style="padding: 0;">
    <v-row dense>
        <slot name="head"/>
        <v-col cols="12"><CameraCard :title=this.title :loadAngleFunction=this.loadAngleFunction></CameraCard></v-col>
        <v-col cols="12"><FormCard :title=title :subtitle=subtitle :submitFunction=submitFunction :clearFunction=clearFunction :clearText=clearText :icon=icon><template v-slot:title><slot name="title"></slot></template><slot name="default"/></FormCard></v-col>
    </v-row></v-container>
  </v-tab-item>
</template>

<script>
import CameraCard from './CameraCard'
import FormCard from './FormCard'
export default {
  props:{
      title: String,
      subtitle: String,
      clearFunction: Function,
      clearText: String,
      submitFunction: Function,
      loadAngleFunction: Function,
      icon: String,
  },
  components: {
    CameraCard,
    FormCard
  }
}
</script>

<style>

</style>