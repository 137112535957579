<template>
    <single-card-form-tab title="History" subtitle="Forms Recently Submitted by current EngineerRACF" :showClear=false :showSave=false>
        <v-btn @click="triggerFactoryCard = true" v-show="!(this.$store.state.forms.Common.fields.EngineerRACF != null && typeof this.$store.state.forms.Common.fields.EngineerRACF == 'object')">Select Engineer from the list</v-btn>
        <div v-show="this.$store.state.forms.Common.fields.EngineerRACF != null && typeof this.$store.state.forms.Common.fields.EngineerRACF == 'object'">
            <V-tabs fixed-tabs v-model="tab" center-active show-arrows="">
                <v-tabs-slider></v-tabs-slider>
                <v-tab>REBA</v-tab>
                <v-tab>RULA</v-tab>
                <v-tab>NIOSH</v-tab>
            </V-tabs>
            <v-tabs-items v-model="tab">
            <v-tab-item> <!-- REBA -->
                <v-list>
                    <v-list-item-group>
                        <v-list-item v-for="item in Lists.SubmittedFormsREBA" :key="item.url">
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{item.Date | formatDate}}
                                    | Worker RACF {{item.WorkerRACF}}
                                </v-list-item-title>
                                <v-list-item-content>
                                    REBA Score {{item.REBAScore}}
                                </v-list-item-content>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn icon @click="editForm(item)"><v-icon>mdi-pencil</v-icon></v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-tab-item>
            <v-tab-item> <!-- RULA -->
                <v-list>
                    <v-list-item-group>
                        <v-list-item v-for="item in Lists.SubmittedFormsRULA" :key="item.url">
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{item.Date | formatDate}}
                                    | Worker RACF {{item.WorkerRACF}}
                                </v-list-item-title>
                                <v-list-item-content>
                                    RULA Score {{item.RULAScore}}
                                </v-list-item-content>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn icon @click="editForm(item)"><v-icon>mdi-pencil</v-icon></v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-tab-item>
            <v-tab-item> <!-- NIOSH -->
                <v-list>
                    <v-list-item-group>
                        <v-list-item v-for="item in Lists.SubmittedFormsNIOSH" :key="item.url">
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{item.Date | formatDate}}
                                    Worker RACF {{item.WorkerRACF}}
                                </v-list-item-title>
                                <v-list-item-content>
                                    RWLOrigin {{item.RWLOrigin}}
                                    RWLDestination {{item.RWLDestination}}
                                    LIOrigin {{item.LIOrigin}}
                                    LIDestination {{item.LIDestination}}
                                </v-list-item-content>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn icon @click="editForm(item)"><v-icon>mdi-pencil</v-icon></v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-tab-item>
            </v-tabs-items>
        </div>
    </single-card-form-tab>
</template>

<script>
import SingleCardFormTab from './SingleCardFormTab'

export default {
    components: {SingleCardFormTab},
    data: ()=>({
        tab: null,
    }),
    computed: {
        Lists: {
            get(){
                return this.$store.state.forms.Lists
            }
        },
        triggerFactoryCard: {
            set(val){
                if(val)
                this.$store.dispatch('triggerFactoryCard')
            }
        }
    },
    methods: {
        editForm(item){
            this.$store.dispatch('editForm', item)
        },
        loadHistory(){
            if (this.$store.state.forms.Common.fields.EngineerRACF == null) {
                 this.$store.state.methods.callSnackbar("No Engineer RACF selected")

            } else {
                this.$store.dispatch('loadSubmittedForms')
                // this.$store.state.methods.callSnackbar("loading submitted forms for RACF " + this.$store.state.forms.Common.fields.EngineerRACF)
            }
            
        }
    }

}
</script>

<style>

</style>