<template>
  <v-tab-item>
    <v-container fluid style="padding: 0;">
    <v-row dense>
        <slot name="head"/>
        <v-col cols="12"><FormCard :title=title :subtitle=subtitle :submitFunction=submitFunction :clearFunction=clearFunction :icon=icon :showClear=showClear :showSave=showSave><slot name="default"/></FormCard></v-col>
    </v-row></v-container>
  </v-tab-item>
</template>

<script>
import FormCard from './FormCard'
export default {
  props:{
      title: String,
      subtitle: String,
      clearFunction: Function,
      submitFunction: Function,
      icon: String,
      showClear: Boolean,
      showSave: Boolean,
  },
  components: {
    FormCard
  }
}
</script>

<style>

</style>