<template>
  <form-card title="Worker Info" :subtitle=subtitle icon="mdi-face" :clearFunction=clearForm :submitFunction=triggerFactoryCard saveText="Close" :showSave=true>
    <v-row>
      <v-col>
      <v-combobox clearable label="RACF" :items="lists.Worker" item-text="RACF" item-value="url" v-model="addRACF"
          :rules="[
            value => typeof value != 'string'
            ||  value.length <= 7
            || 'RACF must be 7 characters or under'
          ]"
      />
      </v-col>
      <v-col v-show="showAddButton" style="max-width: 70px">
        <v-spacer/>
            <v-btn icon @click="addNewWorker">
            <v-icon>mdi-plus</v-icon>
            </v-btn>
        <v-spacer/>
      </v-col>
    </v-row>
    <v-text-field @change="showUpdateButton = true" :rules="[value => value < 9999.99 || 'Cannot be larger than 9999.99', value => value > 0  || 'Must be greater than 0']" clearable v-model="fields.Height" type="number" label="Height" suffix="mm"/>
    <v-text-field @change="showUpdateButton = true" :rules="[value => value < 9999.99 || 'Cannot be larger than 9999.99', value => value > 0  || 'Must be greater than 0']" clearable v-model="fields.Weight" type="number" label="Weight" suffix="kg"/>
    <center>
      <v-text-field label="Worker Info Last Modified Date" disabled v-model="fields.Date"></v-text-field>
      <!-- <v-date-picker clearable full-width style="max-width: 300px" v-model="fields.Date" label="DateDate"/> -->
      <v-btn v-show="showUpdateButton && !showAddButton && !customHeightWeight" @click="updateWorker">Upload Changes</v-btn>
    </center>
  </form-card>
</template>

<script>
import FormCard from '../components/FormCard'
export default {
    data: ()=>({
      addRACF: null,
      subtitle: "Assessed Worker Form",
      showAddButton: false,
      showUpdateButton: false,
      customHeightWeight: false,
    }),
    computed: {
      fields: {
        get(){
          return this.$store.state.forms.Worker.fields
        },
        set(value){
          this.$store.dispatch('updateWorker', value)
        }
      },
      storeRACF: {
        get(){
          return this.$store.state.forms.Worker.fields.RACF
        }
      },
      lists: {
        get(){
          return this.$store.state.forms.Lists
        }
      },
    },
    components: {
        FormCard,
    },
    watch: {
      addRACF: function (val){
        if(val == null) return
        if(typeof val == "object"){
          this.showAddButton = false
          this.customHeightWeight = false
          this.fields.RACF = val
          this.fields.Height = val.Height
          this.fields.Weight = val.Weight
          let isoDate = val.Date
          let itemDate = new Date(isoDate)
          this.fields.Date = itemDate.getFullYear() + '-' + (itemDate.getMonth()+1) + '-' +  itemDate.getDate();
          this.showUpdateButton = false;
        } else {
          //check if need to show add button
          //if exists in list, don't show add button.
          if(this.lists.Worker.find(el => el.RACF == val) != undefined){
            this.customHeightWeight = true
          } else {
            this.showAddButton = true
          }
        }
      },
      storeRACF: function (val) {
        this.addRACF = val
      }
    },
    methods: {
      clearForm(){
        this.$store.dispatch('clearWorker')
      },
      triggerFactoryCard(){
        this.$store.dispatch('triggerFactoryCard')
      },
      validWorker(){
        if(this.addRACF == ""){
          this.$store.state.methods.callSnackbar("RACF must not be empty","red")
          return false
        }
        if(this.addRACF.length > 7){
          this.$store.state.methods.callSnackbar("Cannot have more than 7 characters for RACF","red")
          return false
        }
        if(this.fields.Height <= 0 || this.fields.Height > 9999.99){
          this.$store.state.methods.callSnackbar("Must be greater than 0, less than 10000","red")
          return false
        }
        if(this.fields.Weight <= 0 || this.fields.Weight > 9999.99){
          this.$store.state.methods.callSnackbar("Must be greater than 0, less than 10000","red")
          return false
        }
        if(this.fields.Date == null){
          this.$store.state.methods.callSnackbar("Worker Date must not be empty","red")
          return false
        }
        return true
      },
      addNewWorker(){
        if(!this.validWorker()) return
        this.$store.dispatch('addNewWorker', [this.addRACF, this.fields.Height, this.fields.Weight, this.fields.Date])
        this.showAddButton = false
      },
      updateWorker(){
        if(!this.validWorker()) return
        this.$store.dispatch('updateThisWorker', [this.addRACF.RACF, this.fields.Height, this.fields.Weight, this.getNowAsDateAndTime(), this.addRACF.url])
        this.showUpdateButton = false
      },
      getNowAsDateAndTime(){
        var now = new Date();
        return now.getFullYear() + '-' + (now.getMonth()+1) + '-' +  now.getDate();
      },
      setNowAsDateAndTime(){
        var now = new Date();
        this.fields.Date = now.getFullYear() + '-' + (now.getMonth()+1) + '-' +  now.getDate();
      },
    },
    mounted: function() {
      this.setNowAsDateAndTime();
    }
}
</script>

<style>

</style>