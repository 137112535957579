<template>
    <v-card>
    <v-card-title><v-icon left v-if="icon">{{ icon }}</v-icon>{{ title }}<v-spacer /><slot name="title"></slot>
    <slot name="cornerButton"></slot>
    </v-card-title>
    <v-card-subtitle>{{ subtitle }}</v-card-subtitle>
    <v-card-text>
        <v-form>
            <slot>Default Form Text</slot>
        </v-form>
        <!-- Test text field -->
        <!-- <v-text-field clearable v-for="i in [1,2,3,4,5]" :key=i :label="i.toString() + ' text field'"></v-text-field> -->
    </v-card-text>
    <v-card-actions :hidden="!showClear && !showSave">
        <v-btn text color="error" @click="clearFunction()" :hidden=!showClear>{{ clearText }}</v-btn>
        <v-spacer/>
        <v-btn text color="info" @click="submitFunction()" :hidden=!showSave>{{ saveText }}</v-btn>
        <!-- <v-btn text color="info" @click="submitFunction(); clearFunction()" :hidden=!showSave>{{ submitText }}</v-btn> -->
    </v-card-actions>
    </v-card>
</template>

<script>
export default {
    props: {
        title: {
          type:  String,
          default: "Default FormCard Title",
        },
        subtitle: {
          type:  String,
          default: "Default FormCard subtitle",
        },
        clearFunction: {
            type: Function,
            default: function(){
                console.log("undefined clear function was called");
            },
        },
        submitFunction: Function,
        icon: String,
        clearText: {
            type: String,
            default: "Clear"
        },
        saveText: {
            type: String,
            default: "Save"
        },
        showClear: {
            type: Boolean,
            default: true
        },
        showSave: {
            type: Boolean,
            default: true
        },
        submitText: {
            type: String,
            default: "Submit"
        }
    },
}
</script>

<style>

</style>