<template>
  <form-card title="Engineer Info" icon="mdi-face" :subtitle=subtitle :clearFunction=clearForm :submitFunction=triggerFactoryCard saveText="Close" :showSave=true>
    <v-row>
      <v-col>
        <v-combobox clearable label="Engineer RACF" :items="lists.Engineer" item-text="RACF" item-value="url" v-model="addEngineerRACF"
        :rules="[
          value => typeof value != 'string'
          ||  value.length <= 7
          || 'RACF must be 7 characters or under'
        ]"
        ></v-combobox>
      </v-col>
      <v-col v-show="showAddButton" style="max-width: 70px">
        <v-spacer/>
            <v-btn icon @click="addNewEngineer">
            <v-icon>mdi-plus</v-icon>
            </v-btn>
        <v-spacer/>
      </v-col>
    </v-row>
  </form-card>
</template>

<script>
import FormCard from '../components/FormCard'
export default {
    data: ()=>({
      addEngineerRACF: null,
      subtitle: "Observing Engineer Form",
      showAddButton: false,
    }),
    computed: {
      fields: {
        get(){
          return this.$store.state.forms.Common.fields
        },
        set(value){
          this.$store.dispatch('updateCommon', value)
        }
      },
      lists: {
        get(){
          return this.$store.state.forms.Lists
        }
      },
      UI: {
        get(){
            return this.$store.state.UI
        },
        set(val){
            this.$store.dispatch('updateUI', val)
        }
      },
      storeRACF: {
        get(){
          return this.fields.EngineerRACF
        }
      }
    },
    components: {
        FormCard,
    },
    watch: {
      addEngineerRACF: function (val){
        if(typeof val == "object"){
          this.showAddButton = false
          this.fields.EngineerRACF = val
          this.selectedEngineerChanged()
        } else {
          this.showAddButton = true
        }
      },
      storeRACF: function (val) {
        this.addEngineerRACF = val
      }
    },
    methods: {
      clearForm(){
        this.$store.dispatch('clearEngineer')
        this.addEngineerRACF = null
        this.fields.EngineerRACF = null
      },
      /**
       * Perform tasks when engineer have changed
       * - Clear list loaded lists which depended on EngineerRACF such as SubmittedForms list
       * @author Tiger Kaovilai <pkaovil@ncsu.edu>
       */
      selectedEngineerChanged(){
        this.$store.dispatch('selectedEngineerChanged')
      },
      addNewEngineer(){
        if(this.addEngineerRACF == ""){
          this.$store.state.methods.callSnackbar("RACF must not be empty","red")
          return
        }
        if(this.addEngineerRACF.length > 7){
          this.$store.state.methods.callSnackbar("Cannot have more than 7 characters for RACF","red")
          return
        }
        this.$store.dispatch('addNewEngineer', this.addEngineerRACF)
        this.showAddButton = false
      },
      triggerFactoryCard(){
        this.$store.dispatch('triggerFactoryCard')
      },
    }
}
</script>

<style>

</style>