<template>
    <div>
        <div v-show="common.fields.WorkCenter != null">
        <v-row>
            <v-col>
            <v-combobox return-object solo clearable persistent-hint hint="Available tasks based on selected Work Center" label="Task Name" :items="lists.Tasks" item-text="TaskName" item-value="url" v-model="addTaskName"
              :rules="[
                    value => typeof value != 'string'
                    ||  value.length > 0
                    || 'Task name must not be empty'
                ]"
            ></v-combobox>
            </v-col>
            <v-col>
            <v-combobox return-object solo clearable persistent-hint hint="" label="Task Description" :items="lists.Tasks" item-text="Description" item-value="url" v-model="addTaskDescription"
                :rules="[
                    value => typeof value != 'string'
                    ||  value.length > 0
                    || 'Task description must not be empty'
                ]"
            ></v-combobox>
            </v-col>
            <v-col style="max-width: 70px" v-show="showAddButton">
                <v-spacer/>
                    <v-btn elevation="2" color="blue" icon @click="addNewTask">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                <v-spacer/>
            </v-col>
        </v-row>
        <!-- below helps tell users to select work center -->
        </div>
        <div v-ripple @click="UI.triggerFactoryCard = true">
            <v-select v-show="common.fields.WorkCenter == null" disabled solo clearable persistent-hint hint="Available tasks based on selected Work Center" label="Please select Work Center" background-color="grey"></v-select>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        addTaskName: null,
        addTaskDescription: null,
        showAddButton: false
    }),
    watch:{
        addTaskName: function (val){
            console.log(val)
            if(typeof val == "object"){
                this.addTaskDescription = val
                this.showAddButton = false
                this.common.fields.TaskID = val
            } else {
                if(typeof this.addTaskDescription == "object")
                    this.addTaskDescription = ""
                this.showAddButton = true
            }
        },
        addTaskDescription: function (val){
            console.log(val)
            if(typeof val == "object"){
                this.addTaskName = val
                this.showAddButton = false
                this.common.fields.TaskID = val
            } else {
                if(typeof this.addTaskName == "object")
                    this.addTaskName = ""
                this.showAddButton = true
            }
        },
        storeTaskID: function (val){
            this.addTaskName = val
            this.addTaskDescription = val
        }
    },
    computed: {
        common: {
            get() {
                return this.$store.state.forms.Common
            }
        },
        storeTaskID: {
            get() {
                return this.common.fields.TaskID
            }
        },
        lists: {
            get(){
                return this.$store.state.forms.Lists
            },
        },
        UI: {
            get(){
                return this.$store.state.UI
            },
            set(val){
                this.$store.dispatch('updateUI', val)
            }
        },
    },
    methods: {
        addNewTask(){
            if(this.addTaskName == "" || this.addTaskDescription == ""){
                this.$store.state.methods.callSnackbar("Task Name and Task Description must not be empty","red")
                return
            }
            this.$store.dispatch('addNewTask', [this.addTaskName, this.addTaskDescription])
        },
    }
}
</script>

<style>

</style>