<template>
  <PageCameraFormTab :title=title :subtitle=subtitle :submitFunction=this.submitFunction :clearFunction=clearFunction :loadAngleFunction=this.loadAngleFunction clearText="New" icon="mdi-human">    <template v-slot:title><v-text-field label="REBA Score" style="max-width: 65px; max-height: 0px" disabled v-model="fields.RebaScore"/> </template>
    <template v-slot:default>
      <select-task-field/>
      <center>
      <!-- 
      <v-text-field clearable v-model="EngineerID" label="EngineerID"/> 
      -->
        <V-tabs fixed-tabs v-model="tab" center-active show-arrows="">
          <v-tabs-slider></v-tabs-slider>
          <v-tab>Info</v-tab>
          <v-tab>Neck</v-tab>
          <v-tab>Trunk</v-tab>
          <v-tab>Legs</v-tab>
          <v-tab>Load</v-tab>
          <v-tab>Arms</v-tab>
          <v-tab>Wrist</v-tab>
          <v-tab>Scores</v-tab>
        </V-tabs>
        <!-- <v-card flat > -->
        <v-tabs-items v-model="tab" style="padding-top:10px">
          <v-tab-item>
            <!-- Info Tab -->
            <h3>Time and Date of Assessment</h3>
            <v-date-picker @change="validateDate" clearable v-model="fields.DateDate" label="DateDate" class="ma-4"/>
            <v-time-picker @input="validateDate" clearable v-model="fields.DateTime" label="DateTime" class="ma-4"/>
            <!-- For adding -->
          </v-tab-item>
          <v-tab-item>
            <!-- Neck Tab -->
            <v-row justify="space-around">              
              <v-col cols="12" md="6" lg="8">
                <h3 style="padding-top:20px">Neck Angle</h3>
                <v-slider
                thumb-size="35" thumb-label="always" style="padding-top: 50px" 
                v-model="fields.NeckPositionAngle"
                :max="limits.neckAngle_max"
                :min="limits.neckAngle_min"                
                @change="updateRebaScore"
                step="1"
                >
                    <template v-slot:prepend>
                      <div class="rangeSlotDiv">
                        <div>
                          Extension
                        </div>
                        <v-icon>mdi-minus</v-icon>
                      </div>
                      
                    </template>                    
                    <template v-slot:append>
                      <div class="rangeSlotDiv">
                        <v-icon>mdi-plus</v-icon>
                        <div>Flexion</div>
                        <v-text-field class="rangeTextfield" @change="updateRebaScore" clearable v-model="fields.NeckPositionAngle" type="number" suffix="°"
                          :rules="[
                            value => !(value > limits.neckAngle_max || value < limits.neckAngle_min) || 'Has to be between ' + limits.neckAngle_max  +' and ' + limits.neckAngle_min,
                          ]"/>
                        </div>
                    </template>
                </v-slider>
              </v-col>
              <v-col><v-checkbox @change="updateRebaScore" clearable v-model="fields.NeckTwist" :rules="[ruleNeckTwistSideBend]" label="Neck Twist"/></v-col>
              <v-col><v-checkbox @change="updateRebaScore" clearable v-model="fields.NeckSideBend" :rules="[ruleNeckTwistSideBend]" label="Neck Sidebending"/></v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <!-- Trunk Tab -->
            <v-row>
              <v-col cols="12" md="6" lg="8">
                <h3 style="padding-top:20px">Trunk Angle</h3>
                <v-slider
                thumb-size="35" thumb-label="always" style="padding-top: 50px"
                v-model="fields.TrunkAngle"
                @change="updateRebaScore" 
                :max="limits.trunkAngle_max"
                :min="limits.trunkAngle_min"
                step="1"
                >
                    <template v-slot:prepend><div class="rangeSlotDiv">Extension<v-icon>mdi-minus</v-icon></div></template>
                    <template v-slot:append><div class="rangeSlotDiv"><v-icon>mdi-plus</v-icon> Flexion
                      <v-text-field @change="updateRebaScore" class="rangeTextfield" clearable v-model="fields.TrunkAngle" type="number" suffix="°" :rules="[value => !(value > limits.trunkAngle_max || value < limits.trunkAngle_min) || 'Has to be between ' + limits.trunkAngle_max  +' and ' + limits.trunkAngle_min]"/></div></template>
                </v-slider>
              </v-col>
              <v-col><v-checkbox clearable @change="updateRebaScore" v-model="fields.TrunkTwist" :rules="[ruleTrunkTwistSideBend]" label="TrunkTwist"/></v-col>
              <v-col><v-checkbox clearable @change="updateRebaScore" v-model="fields.TrunkSideBend" :rules="[ruleTrunkTwistSideBend]" label="TrunkSideBend"/></v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <!-- Leg Angle Tab -->
            <v-row>
              <v-col cols="12" md="6" lg="8">
                <h3 style="padding-top:20px">Leg Angle</h3>
                <v-slider
                thumb-size="35" thumb-label="always" style="padding-top: 50px"
                @change="updateRebaScore" v-model="fields.LegAngle"
                :max="limits.legAngle_max"
                :min="limits.legAngle_min"
                step="1"
                >
                    <template v-slot:prepend><div class="rangeSlotDiv">Extension<v-icon>mdi-minus</v-icon></div></template>
                    <template v-slot:append><div class="rangeSlotDiv"><v-icon>mdi-plus</v-icon>Flexion<v-text-field class="rangeTextfield" clearable @change="updateRebaScore" v-model="fields.LegAngle" type="number" suffix="°" :rules="[value => !(value > limits.legAngle_max || value < limits.legAngle_min) || 'Has to be between ' + limits.legAngle_max  +' and ' + limits.legAngle_min]"/></div></template>
                </v-slider>
              </v-col>
              <v-col style="height:300px">
                <p>Leg Geometry</p>
                <p>Select straight or asymmetrical leg support: </p>
                <v-btn-toggle @change="updateRebaScore" v-model="fields.LegGeometry" mandatory>
                  <v-btn value=false height="200px">
                    <v-img contain height="190px" :src=straightLegImage />
                  </v-btn>
                  <v-btn value=true height="200px">
                    <v-img contain height="190px" :src=bentLegImage />
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <!-- Force Load Tab -->
            <v-text-field clearable type="number" v-model="fields.ForceLoad" @change="updateForceNewton" label="Force load" suffix="newtons" :rules="[value=> !(value > 999 || value < 0) || 'everything ok bro?']" />
            <v-text-field clearable type="number" v-model="fields.ForceLoadKg" @change="updateForceKg" label="Force load" suffix="kilograms"/>
            <v-text-field clearable type="number" v-model="fields.ForceLoadLb" @change="updateForceLb" label="Force load" suffix="pound-force"/>
            <v-checkbox @change="updateRebaScore" label="Shock or rapid build up of force" v-model="fields.ShockOrRapidBuildUp"/>
          </v-tab-item>
          <v-tab-item>
            <h3 style="padding-top:20px">Upper Arm Angle</h3>
            <v-slider
                thumb-size="35" thumb-label="always" style="padding-top: 50px"
                @change="updateRebaScore" v-model="fields.UpperArmAngle"
                :max="limits.upperArmAngle_max"
                :min="limits.upperArmAngle_min"
                step="1"
                >
                    <template v-slot:prepend><div class="rangeSlotDiv">Extension<v-icon>mdi-minus</v-icon></div></template>
                    <template v-slot:append><div class="rangeSlotDiv"><v-icon>mdi-plus</v-icon> Flexion
                      <v-text-field class="rangeTextfield" clearable @change="updateRebaScore" v-model="fields.UpperArmAngle" type="number" suffix="°" :rules="[value => !(value > limits.upperArmAngle_max || value < limits.upperArmAngle_min) || 'Has to be between ' + limits.upperArmAngle_max  +' and ' + limits.upperArmAngle_min]"/></div></template>
                </v-slider>
            <v-row>
              <v-col>
                <v-checkbox clearable @change="updateRebaScore" v-model="fields.ShoulderRaised" label="Shoulder Raised"/>
              </v-col>
              <v-col>
                <v-checkbox clearable @change="updateRebaScore" v-model="fields.UpperArmAbducted" label="Upper Arm Abducted"/>
              </v-col>
              <v-col>
                <v-checkbox clearable @change="updateRebaScore" v-model="fields.ArmSupportOrLean" label="Arm Supported Or Leaning"/>
              </v-col>
            </v-row>
            <h3 style="padding-top:20px">Lower Arm Angle</h3>            
            <v-slider
                thumb-size="35" thumb-label="always" style="padding-top: 50px"
                @change="updateRebaScore" v-model="fields.LowerArmAngle"
                :max="limits.lowerArmAngle_max"
                :min="limits.lowerArmAngle_min"
                step="1"
                >
                    <template v-slot:prepend><div class="rangeSlotDiv">Extension<v-icon>mdi-minus</v-icon></div></template>

                    <template v-slot:append><div class="rangeSlotDiv"><v-icon>mdi-plus</v-icon> Flexion<v-text-field class="rangeTextfield" clearable @change="updateRebaScore" v-model="fields.LowerArmAngle" type="number" suffix="°" :rules="[value => !(value > limits.lowerArmAngle_max || value < limits.lowerArmAngle_min) || 'Has to be between ' + limits.lowerArmAngle_max  +' and ' + limits.lowerArmAngle_min]"/></div></template>
            </v-slider>
          </v-tab-item>
          <v-tab-item>
            <!-- Wrists tab -->
            <h3 style="padding-top:20px">Wrist Angle</h3>
            <v-slider
                thumb-size="35" thumb-label="always" style="padding-top: 50px"
                @change="updateRebaScore" v-model="fields.WristAngle"
                :max="limits.wristAngle_max"
                :min="limits.wristAngle_min"
                step="1"
                >
                <template v-slot:prepend><div class="rangeSlotDiv">Extension<v-icon>mdi-minus</v-icon></div></template>
                    <template v-slot:append><div class="rangeSlotDiv"><v-icon>mdi-plus</v-icon> Flexion<v-text-field class="rangeTextfield" clearable @change="updateRebaScore" v-model="fields.WristAngle" type="number" suffix="°" :rules="[value => !(value > limits.wristAngle_max || value < limits.wristAngle_min) || 'Has to be between ' + limits.wristAngle_max  +' and ' + limits.wristAngle_min]"/></div></template>
            </v-slider>
            <v-checkbox clearable @change="updateRebaScore" v-model="fields.WristTwist" label="WristTwist"/>
          </v-tab-item>
          <v-tab-item>
            <v-radio-group
            
             @change="updateRebaScore" v-model="fields.CouplingScore"
             label="Coupling Score"
             hint="Well fitting Handle and mid rang power grip, good: +0
Acceptable but not ideal hand hold or coupling
acceptable with another body part, fair: +1
Hand hold not acceptable but possible, poor: +2
No handles, awkward, unsafe with any body part,
Unacceptable: +3"
:persistent-hint="showHint"
             row>
             <v-radio
              label="Good"
              value=0
              
             />
             <v-radio
              label="Fair"
              value=1
             />
             <v-radio
              label="Poor"
              value=2
             />
             <v-radio
              label="Unacceptable"
              value=3
             />
            </v-radio-group>
      <v-text-field disabled clearable @change="updateRebaScore" v-model="fields.CouplingScore" label="CouplingScore"/>
            <v-radio-group
            
             @change="updateRebaScore" v-model="fields.ActivityScore"
             label="Activity level"
             >
             <v-radio
              label="None"
              value=0
              
             />
             <v-radio
              label="1 or more body parts are held longer than 1 minute"
              value=1
             />
             <v-radio
              label="Repeated small range actions (more than 4x per minute)"
              value=2
             />
             <v-radio
              label="Action causes rapid large range changes in postures or unstable base"
              value=3
             />
            </v-radio-group>
      <!-- <v-checkbox v-model="fields.ActivityHeldLong" @change="updateActivityScore" label=/>
      <v-checkbox v-model="fields.ActivitySmallRangeActions" @change="updateActivityScore" label=/>
      <v-checkbox v-model="fields.ActivityRapidLargeRange" @change="updateActivityScore" hint="Multiple selections were detected. Only one of the selected options will be added to the final REBA/RULA score, but all selections will be recorded to the database." 
      :persistent-hint="fields.ActivityHeldLong + fields.ActivitySmallRangeActions + fields.ActivityRapidLargeRange > 1" label=/> -->
      <v-text-field @change="updateRebaScore" clearable v-model="fields.ActivityScore" disabled label="ActivityScore"/>
          </v-tab-item>
          <v-tab-item>
          </v-tab-item>
        </v-tabs-items>
        <!-- </v-card> -->
        <v-row>
          <v-col>
            <v-checkbox v-model="showHint" label="Show hint"/>
          </v-col>
        </v-row>
        <!-- <div id="responseDebug">{{responseDebug}}</div> -->

      </center>
    </template>
    <!-- <slot name="head"></slot> -->
  </PageCameraFormTab>
</template>

<script>
import PageCameraFormTab from './PageCameraFormTab'
import SelectTaskField from './SelectTaskField.vue'
// var straightLegImage = require('../assets/straightleg.png');
// var bentLegImage = require('../assets/onelegbent.png');
export default {
  data: ()=>({
      title: "REBA Form",
      subtitle: "REBA Entire Body Assessment Form",
      tab: null,
      straightLegImage: require('../assets/straightleg.png'),
      bentLegImage: require('../assets/onelegbent.png'),
      showHint: false,
      rebaScoreHint: "",
  }),
  computed: {
    cameraCard: {
      get() {
        return this.$store.state.cameraCard
      }
    },
    common: {
      get() {
        return this.$store.state.forms.Common
      }
    },
    limits: {
        get() {
          return this.$store.state.limits
        }
      },
    fields: {
      get(){
        return this.$store.state.forms.REBA.fields
      },
      set(value){
        this.$store.dispatch('updateREBA', value)
      }
    },
    lists: {
      get(){
        return this.$store.state.forms.Lists
      },
    },
    UI: {
      get(){
        return this.$store.state.UI
      },
      set(val){
        this.$store.dispatch('updateUI', val)
      }
    },
    responseDebug: {
      get(){
        return this.$store.state.UI.responseDebug
      },
      set(value){
        this.$store.dispatch('updateREBA', value)
      }
    },
    conversions: {
      get(){
        return this.$store.state.conversions
      }
    }
  },
  components: {
    PageCameraFormTab,
    SelectTaskField
  },
  mounted: function() {
    this.setNowAsDateAndTime();
  },
  methods: {
    updateForceKg(){
      this.fields.ForceLoad = (this.fields.ForceLoadKg*this.conversions.kgToNewtons).toFixed(2)
      this.updateForceNewton()
    },
    updateForceLb(){
      this.fields.ForceLoad = (this.fields.ForceLoadLb*this.conversions.lbToNewtons).toFixed(2)
      this.updateForceNewton()
    },
    updateForceNewton(){
      this.fields.ForceLoadKg = (this.fields.ForceLoad/this.conversions.kgToNewtons).toFixed(2)
      this.fields.ForceLoadLb = (this.fields.ForceLoad/this.conversions.lbToNewtons).toFixed(2)
      this.updateRebaScore();
    },
    ruleNeckTwistSideBend(){
      return !(this.fields.NeckTwist && this.fields.NeckSideBend) || 'cannot be sidebend and twist'
    },
    ruleTrunkTwistSideBend(){
      return !(this.fields.TrunkTwist && this.fields.TrunkSideBend) || 'cannot be sidebend and twist'
    },
    setNowAsDateAndTime(){
      var now = new Date();
      this.fields.DateDate = now.getFullYear() + '-' + (now.getMonth()+1) + '-' +  now.getDate();
      console.log(this.fields.DateDate)
      this.fields.DateTime = now.getHours() + ':' + now.getMinutes();
    },
    submitFunction(){
      this.$store.dispatch('submitREBA')
    },
    clearFunction(){
      this.$store.dispatch('clearREBA')
    },
    validateDate(){
      function getLocalDate(DateDate,DateTime){
        var localDateVal = DateDate.split('-');
        var localDate = new Date();
        localDate.setFullYear(localDateVal[0])
        localDate.setMonth(localDateVal[1]-1)
        localDate.setDate(localDateVal[2])
        if(DateTime != null){
          var localTime = DateTime.split(':');
          localDate.setHours(localTime[0],localTime[1]);
        } else {
          localDate.setHours(0,0);
        }
        return localDate;
      }
      let localDateNum = getLocalDate(this.fields.DateDate,this.fields.DateTime).getTime()
      let now = Date.now()
      if (now < localDateNum){
        this.fields.DateDate = null
        this.fields.DateTime = null
        this.$store.state.methods.callSnackbar("Date cannot be future","red")
      }
    },
    loadAngleFunction(){
      function getAngle(AngleObj, faceLeft) {
        let angle = Math.abs(AngleObj.lines[0].theta() - AngleObj.lines[1].theta())*(180/Math.PI)
      
        if (((AngleObj.nodes[0].x - AngleObj.nodes[1].x < 0 && AngleObj.nodes[2].x - AngleObj.nodes[1].x < 0)
            || (AngleObj.nodes[0].x - AngleObj.nodes[1].x > 0 && AngleObj.nodes[2].x - AngleObj.nodes[1].x > 0))
            ^ AngleObj.reverseAngle){
              angle = 180 - angle
            }

        if (AngleObj.nodes[0].isBelowLine(AngleObj.nodes[1], AngleObj.lines[1]) ^ AngleObj.lines[1].slope() < 0 ^ faceLeft)
        {
          angle = angle * -1
        }

        if (AngleObj.invertAngle){
          angle = angle * -1
        }

        return angle
      }
      let NeckPositionAngle = getAngle(this.$store.state.cameraCard[this.title]["dict"]["neckAngle"],this.$store.state.cameraCard[this.title]["faceLeft"]).toFixed(0)
      let TrunkAngle = getAngle(this.$store.state.cameraCard[this.title]["dict"]["trunkAngle"],this.$store.state.cameraCard[this.title]["faceLeft"]).toFixed(0)
      let LegAngle = getAngle(this.$store.state.cameraCard[this.title]["dict"]["legAngle"],this.$store.state.cameraCard[this.title]["faceLeft"]).toFixed(0)
      let UpperArmAngle = getAngle(this.$store.state.cameraCard[this.title]["dict"]["upperArmAngle"],this.$store.state.cameraCard[this.title]["faceLeft"]).toFixed(0)
      let LowerArmAngle = getAngle(this.$store.state.cameraCard[this.title]["dict"]["lowerArmAngle"],this.$store.state.cameraCard[this.title]["faceLeft"]).toFixed(0)
      let WristAngle = getAngle(this.$store.state.cameraCard[this.title]["dict"]["wristAngle"],this.$store.state.cameraCard[this.title]["faceLeft"]).toFixed(0)
      if(NeckPositionAngle < this.limits.neckAngle_min || NeckPositionAngle > this.limits.neckAngle_max){
        this.$store.state.methods.callSnackbar("Please adjust NeckPositionAngle","red")
        return
      }
      if(TrunkAngle < this.limits.trunkAngle_min || TrunkAngle > this.limits.trunkAngle_max){
        this.$store.state.methods.callSnackbar("Please adjust TrunkAngle","red")
        return
      }
      if(LegAngle < this.limits.leglegAngle_min || LegAngle > this.limits.leglegAngle_max){
        this.$store.state.methods.callSnackbar("Please adjust LegAngle","red")
        return
      }
      if(UpperArmAngle < this.limits.upperArmAngle_min || UpperArmAngle > this.limits.upperArmAngle_max){
        this.$store.state.methods.callSnackbar("Please adjust UpperArmAngle","red")
        return
      }
      if(LowerArmAngle < this.limits.lowerArmAngle_min || LowerArmAngle > this.limits.lowerArmAngle_max){
        this.$store.state.methods.callSnackbar("Please adjust LowerArmAngle","red")
        return
      }
      if(WristAngle < this.limits.wristAngle_min || WristAngle > this.limits.wristAngle_max){
        this.$store.state.methods.callSnackbar("Please adjust WristAngle","red")
        return
      }
      this.fields.NeckPositionAngle = NeckPositionAngle
      this.fields.TrunkAngle = TrunkAngle
      this.fields.LegAngle = LegAngle
      this.fields.UpperArmAngle = UpperArmAngle
      this.fields.LowerArmAngle = LowerArmAngle
      this.fields.WristAngle = WristAngle
      this.updateRebaScore()
    },
    // updateActivityScore(){
    //   // this.ActivityScore = 0;
    //   // if(this.ActivityHeldLong) this.ActivityScore += 1;
    //   // if(this.ActivitySmallRangeActions) this.ActivityScore += 1;
    //   // if(this.ActivityRapidLargeRange) this.ActivityScore += 1;
    //   // console.log(this.ActivityScore);
    //   this.fields.ActivityScore = this.fields.ActivityHeldLong+this.fields.ActivitySmallRangeActions+this.fields.ActivityRapidLargeRange
    //   this.updateRebaScore();
    // },
    updateRebaScore(){
      this.rebaScoreHint = ""
        + " NeckPositionAngle:"+this.fields.NeckPositionAngle
        + " NeckTwist:"+this.fields.NeckTwist
        + " NeckSideBend:"+this.fields.NeckSideBend
        + " TrunkAngle:"+this.fields.TrunkAngle
        + " TrunkTwist:"+this.fields.TrunkTwist
        + " TrunkSideBend:"+this.fields.TrunkSideBend
        + " LegAngle:"+this.fields.LegAngle
        + " LegGeometry: "+ this.fields.LegGeometry
        + " ForceLoad:"+this.fields.ForceLoad
        + " ForceLoadKg:"+this.fields.ForceLoadKg
        + " ForceLoadLb:"+this.fields.ForceLoadLb
        + " UpperArmAngle:"+this.fields.UpperArmAngle
        + " ShoulderRaised:"+this.fields.ShoulderRaised 
        + " UpperArmAbducted:"+this.fields.UpperArmAbducted
        + " ArmSupportOrLean:"+this.fields.ArmSupportOrLean
        + " ShoulderRaised:"+this.fields.ShoulderRaised
        + " LowerArmAngle:"+this.fields.LowerArmAngle
        + " WristAngle:"+this.fields.WristAngle
        + " WristTwist:"+this.fields.WristTwist
        + " CouplingScore:"+parseInt(this.fields.CouplingScore)
        + " ActivityScore:"+this.fields.ActivityScore
        + " ActivityHeldLong:"+this.fields.ActivityHeldLong
        + " ActivitySmallRangeActions:"+this.fields.ActivitySmallRangeActions
        + " ActivityRapidLargeRange:"+this.fields.ActivityRapidLargeRange
      let pageStore = this.$store.state.forms.REBA.calc
      // Step 1. Neck Position
      function score_a(neck, trunk, legs){
        return pageStore.table_a[neck - 1][trunk - 1][legs - 1]
      }
      function score_b(lower_arm, upper_arm, wrist){
        return pageStore.table_b[lower_arm - 1][upper_arm - 1][wrist - 1]
      }

      function score_c(a, b){
          return pageStore.table_c[a - 1][b - 1]
      }

      function calculate_reba(neck_angle, neck_twist, neck_side_bend, 
                   trunk_angle, trunk_twist, trunk_side_bend, 
                   leg_angle, leg_geometry,
                   force_load, shock_or_rapid_build_up,
                   upper_arm_angle, shoulder_raised, upper_arm_abducted, arm_support_or_lean,
                   lower_arm_angle, 
                   wrist_angle, wrist_twist, 
                   coupling_score, 
                   activity_score){
        let leg_score = 0, neck_score = 0, trunk_score = 0, force_load_score = 0, upper_arm_score = 0, lower_arm_score = 0, wrist_score = 0;
        if (neck_angle >= 20 || neck_angle < 0){
            neck_score = 2 + (neck_twist || neck_side_bend)
        }
        else{
          neck_score = 1 + (neck_twist || neck_side_bend)
          }

        if (trunk_angle >= 60){
          trunk_score = 4 + (trunk_twist || trunk_side_bend)
          }
        else if (trunk_angle >= 20){
          trunk_score = 3 + (trunk_twist || trunk_side_bend)
          }
        else if (trunk_angle != 0){
          trunk_score = 2 + (trunk_twist || trunk_side_bend)
          }
        else{
          trunk_score = 1 + (trunk_twist || trunk_side_bend)
          }

        if (leg_angle > 60){
            leg_score = 3 + leg_geometry
          }
        else if (leg_angle >= 30){
            leg_score = 2 + leg_geometry
          }
        else{
            leg_score = 1 + leg_geometry
          }
            
        // #converted 11 lbs and 22 lbs to kg
        if (force_load < 4.98952){
            force_load_score = 0 + shock_or_rapid_build_up
          }
        else if (force_load < 9.97903){
            force_load_score = 1 + shock_or_rapid_build_up
          }
        else{
            force_load_score = 2 + shock_or_rapid_build_up
        }
        if (upper_arm_angle <= -20){
            upper_arm_score = 2 + shoulder_raised + upper_arm_abducted - arm_support_or_lean
        }
        else if (upper_arm_angle < 20){
            upper_arm_score = 1 + shoulder_raised + upper_arm_abducted - arm_support_or_lean
          }
        else if (upper_arm_angle < 45){
            upper_arm_score = 2 + shoulder_raised + upper_arm_abducted - arm_support_or_lean
        }
        else if (upper_arm_angle < 90){
            upper_arm_score = 3 + shoulder_raised + upper_arm_abducted - arm_support_or_lean
        }
        else{
            upper_arm_score = 4 + shoulder_raised + upper_arm_abducted - arm_support_or_lean
        }
        if(upper_arm_score == 0)
          upper_arm_score = 1
        if (lower_arm_angle <= 60 || lower_arm_angle >= 100){
            lower_arm_score = 2
        }
        else{
            lower_arm_score = 1
        }   
        if (wrist_angle <= -15 || wrist_angle >= 15){
            wrist_score = 2 + wrist_twist
        }
        else{
            wrist_score = 1 + wrist_twist
        }
        let a = score_a(neck_score, trunk_score, leg_score)
        let b = score_b(lower_arm_score, upper_arm_score, wrist_score)
        let c = score_c(a + force_load_score, b + coupling_score)

        if (activity_score > 0){
            return c + 1
        }
        else{
            return c
        }
      }
      // let pageStore = this;
      this.fields.RebaScore = calculate_reba(this.fields.NeckPositionAngle,this.fields.NeckTwist,this.fields.NeckSideBend,this.fields.TrunkAngle,this.fields.TrunkTwist,this.fields.TrunkSideBend,this.fields.LegAngle,this.fields.LegGeometry == "true" ? 1:0,this.fields.ForceLoadKg,this.fields.ShockOrRapidBuildUp,this.fields.UpperArmAngle,this.fields.ShoulderRaised,this.fields.UpperArmAbducted,this.fields.ArmSupportOrLean,this.fields.LowerArmAngle,this.fields.WristAngle,this.fields.WristTwist,parseInt(this.fields.CouplingScore),this.fields.ActivityScore);
      

    }
  }
}
</script>

<style>
.rangeTextfield {
  min-width: 80px;
  margin-left: 5px;
}
.rangeSlotDiv {
  max-height: 24px;
  display: flex;
  align-items: center;
}
</style>