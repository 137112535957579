<template>
  <PageCameraFormTab :title=title :subtitle=subtitle :submitFunction=this.submitFunction :clearFunction=clearFunction clearText="New" icon="mdi-human">
    <template v-slot:title>  </template>
    <template v-slot:default>
      <select-task-field/>
      <v-row>
      <v-col><v-text-field label="RWL Origin" disabled v-model="fields.RWLOrigin" v-if="fields.RWLOrigin"/></v-col>
      <v-col><v-text-field v-if="fields.RWLDestination" label="RWL Destination" disabled v-model="fields.RWLDestination"/></v-col>
      <v-col><p v-show="!fields.Load"> Enter load to see Lifting Index</p><v-text-field label="LI Origin" disabled v-model="fields.LIOrigin" v-if="fields.LIOrigin"/></v-col>
      <v-col v-show="fields.Load"><v-text-field v-if="fields.LIDestination" label="LI Destination" disabled v-model="fields.LIDestination"/></v-col>
      </v-row>
      <center>
      <!-- 
      <v-text-field clearable v-model="EngineerID" label="EngineerID"/> 
      -->
        <V-tabs fixed-tabs v-model="tab" center-active show-arrows="">
          <v-tabs-slider></v-tabs-slider>
          <v-tab>Info</v-tab>
          <v-tab>Origin</v-tab>
          <v-tab>Destination</v-tab>
          <v-tab>Miscellaneous</v-tab>
        </V-tabs>
        <!-- <v-card flat > -->
        <v-tabs-items v-model="tab" style="padding-top:10px">
          <v-tab-item>
            <!-- Info Tab -->
            <h3>Time and Date of Assessment</h3>
            <v-date-picker @change="validateDate" clearable v-model="fields.DateDate" label="DateDate" class="ma-4"/>
            <v-time-picker @input="validateDate" clearable v-model="fields.DateTime" label="DateTime" class="ma-4"/>
            <!-- For adding -->
          </v-tab-item>
          <v-tab-item>
            <!-- Origin -->
            <!-- <v-row justify="space-around">
              <v-col cols="12" md="6" lg="8"> -->
                <v-text-field
                label="Origin Horizontal Distance"
                v-model="fields.HorizontalOriginDistance"
                :hint="horizontalDistanceHint"
                :persistent-hint=showHint
                type="number"
                suffix="mm"
                @change="updateNioshLi"
                :rules="distanceFromBody"
                />
                <v-text-field
                label="Origin Vertical Distance"
                :hint="verticalDistanceHint"
                :persistent-hint=showHint
                v-model="fields.VerticalOriginDistance"
                type="number"
                suffix="mm"
                @change="updateNioshLi"
                :rules="distanceFromBody"
                />
                
              <!-- </v-col>
            </v-row> -->
          </v-tab-item>
          <v-tab-item>
            <!-- Origin -->
            <!-- <v-row justify="space-around">
              <v-col cols="12" md="6" lg="8"> -->
                <v-text-field
                label="Destination Horizontal Distance"
                v-model="fields.HorizontalDestinationDistance"
                :hint="horizontalDistanceHint"
                :persistent-hint=showHint
                type="number"
                suffix="mm"
                @change="updateNioshLi"
                :rules="distanceFromBody"
                />
                <v-text-field
                label="Destination Vertical Distance"
                :hint="verticalDistanceHint"
                :persistent-hint=showHint
                v-model="fields.VerticalDestinationDistance"
                type="number"
                suffix="mm"
                @change="updateNioshLi"
                :rules="distanceFromBody"
                />
                
              <!-- </v-col>
            </v-row> -->
          </v-tab-item>
          <v-tab-item>
            <!-- <v-row>
              <v-col cols="12" md="6" lg="8"> -->
                <h3 style="padding-top:20px">Asymmetry Angle</h3>
                <v-slider
                thumb-size="35" thumb-label="always" style="padding-top: 50px"
                :persistent-hint=showHint
                hint="Angular displacement of the load from the sagittal plane, measured at both the origin and destination of the lift"
                v-model="fields.AsymmetryAngle"
                @change="updateNioshLi" 
                :max="180"
                :min="-180"
                step="1"
                >
                <template v-slot:prepend><v-icon>mdi-minus</v-icon></template>
                <template v-slot:append><v-icon>mdi-plus</v-icon></template>
                </v-slider>
                <v-text-field
                thumb-size="35" thumb-label="always" style="padding-top: 30px"
                label="Load (optional)"
                hint=""
                :persistent-hint=showHint
                v-model="fields.Load"
                type="number"
                suffix="newtons"
                @change="updateNioshLi"
                :rules="[val => val >= 0 || 'Loads must be > 0']"
                />
                <h3 style="padding-top:20px">Work Duration (hours)</h3>
                

                <v-slider
                thumb-size="35" thumb-label="always" style="padding-top: 30px"
                :persistent-hint=showHint
                hint="Duration worker will be performing this task"
                v-model="fields.WorkDuration"
                @change="updateNioshLi"
                :rules="[ val => (val <= 8 && val >= 0) || 'must be between 0 and 8']"
                min="0"
                max="8"
                step="0.1"
                >
                <template v-slot:prepend><v-icon>mdi-minus</v-icon></template>
                <template v-slot:append><v-icon>mdi-plus</v-icon></template>
                </v-slider>

                <h3 style="padding-top:20px">Lifting Frequency</h3>
                <v-slider
                thumb-size="35" thumb-label="always" style="padding-top: 30px"
                hint="Number of lifts completed in a 15 minute span"
                v-model="fields.Frequency"
                suffix="hours"
                :persistent-hint=showHint
                @change="updateNioshLi"
                min="0.2"
                max="15"
                step="0.1"
                >
                <template v-slot:prepend><v-icon>mdi-minus</v-icon></template>
                <template v-slot:append><v-icon>mdi-plus</v-icon></template>
                </v-slider>
                
                <v-radio-group
                
                @change="updateNioshLi" v-model="fields.CouplingCondition"
                label="Coupling Condition"
                hint="quality of interaction between hands and the object"
    :persistent-hint="showHint"
                >
                <v-radio
                  label="Good: lower maximum grasp forces and allow for an easier carrying of the object"
                  value=0
                />
                <v-radio
                  label="Fair"
                  value=1
                />
                <v-radio
                  label="Poor"
                  value=2
                />
                </v-radio-group>
              <!-- </v-col>
            </v-row> -->
          </v-tab-item>
          
        </v-tabs-items>
        <!-- </v-card> -->
        <v-row>
          <v-col>
            <v-checkbox v-model="showHint" label="Show hint"/>
          </v-col>
          <v-col>
            
          </v-col>
        </v-row>
        <!-- <div id="responseDebug">{{responseDebug}}</div> -->

      </center>
    </template>
    <!-- <slot name="head"></slot> -->
  </PageCameraFormTab>
</template>

<script>
import PageCameraFormTab from './PageCameraFormTab'
import SelectTaskField from './SelectTaskField.vue'
export default {
  data: ()=>({
      distanceFromBody: [val => val > 0 || 'Distance from body must be greater than 0'],
      horizontalDistanceHint: "Horizontal distance from the worker's hands to the midpoint between their ankles",
      verticalDistanceHint: "Horizontal distance from the worker's hands to the midpoint between their ankles",
      title:"NIOSH",
      subtitle:"National Institute for Occupational Safety and Health",
      tab: null,
      showHint: false,
      freq_table: [[[1.0, 1.0], [.95, .95], [.85, .85]],
              [[.97, .97], [.92, .92], [.81, .81]],
              [[.94, .94], [.88, .88], [.75, .75]],
              [[.91, .91], [.84, .84], [.65, .65]],
              [[.88, .88], [.79, .79], [.55, .55]],
              [[.84, .84], [.72, .72], [.45, .45]],
              [[.80, .80], [.60, .60], [.35, .35]],
              [[.75, .75], [.50, .50], [.27, .27]],
              [[.70, .70], [.42, .42], [.22, .22]],
              [[.60, .60], [.35, .35], [.18, .18]],
              [[.52, .52], [.30, .30], [  0, .15]],
              [[.45, .45], [.26, .26], [  0, .13]],
              [[.41, .41], [  0, .23], [  0,   0]],
              [[.37, .37], [  0, .21], [  0,   0]],
              [[  0, .34], [  0,   0], [  0,   0]],
              [[  0, .31], [  0,   0], [  0,   0]],
              [[  0, .28], [  0,   0], [  0,   0]],
              [[  0,   0], [  0,   0], [  0,   0]]],
              
       // Used to calculate the coupling multiplier from the coupling condition and vertical distance
      couple_table: [[1.0, 1.0], [.95, 1.0], [.9, .9]],
  }),
  computed: {
    horizontalDists: {
      get(){
        return [this.fields.HorizontalOriginDistance, this.fields.HorizontalDestinationDistance]
      },
      set(val){
        this.fields.HorizontalOriginDistance = val[0]
        this.fields.HorizontalDestinationDistance = val[1]
      }
    },
    common: {
      get() {
        return this.$store.state.forms.Common
      }
    },
    limits: {
        get() {
          return this.$store.state.limits
        }
      },
    fields: {
      get(){
        return this.$store.state.forms.NIOSH.fields
      },
      set(value){
        this.$store.dispatch('updateREBA', value)
      }
    },
    lists: {
      get(){
        return this.$store.state.forms.Lists
      },
    },
    UI: {
      get(){
        return this.$store.state.UI
      },
      set(val){
        this.$store.dispatch('updateUI', val)
      }
    },
    responseDebug: {
      get(){
        return this.$store.state.UI.responseDebug
      },
      set(value){
        this.$store.dispatch('updateREBA', value)
      }
    },
    conversions: {
      get(){
        return this.$store.state.conversions
      }
    }
  },
  components: {
    PageCameraFormTab,
    SelectTaskField
  },
  mounted: function() {
    this.setNowAsDateAndTime();
  },
  methods: {
    updateForceKg(){
      this.fields.ForceLoad = (this.fields.ForceLoadKg*this.conversions.kgToNewtons).toFixed(2)
      this.updateForceNewton()
    },
    updateForceLb(){
      this.fields.ForceLoad = (this.fields.ForceLoadLb*this.conversions.lbToNewtons).toFxied(2)
      this.updateForceNewton()
    },
    updateForceNewton(){
      this.fields.ForceLoadKg = (this.fields.ForceLoad/this.conversions.kgToNewtons).toFixed(2)
      this.fields.ForceLoadLb = (this.fields.ForceLoad/this.conversions.lbToNewtons).toFixed(2)
      this.updateNioshLi();
    },
    ruleNeckTwistSideBend(){
      return !(this.fields.NeckTwist && this.fields.NeckSideBend) || 'cannot be sidebend and twist'
    },
    ruleTrunkTwistSideBend(){
      return !(this.fields.TrunkTwist && this.fields.TrunkSideBend) || 'cannot be sidebend and twist'
    },
    setNowAsDateAndTime(){
      var now = new Date();
      this.fields.DateDate = now.getFullYear() + '-' + (now.getMonth()+1) + '-' +  now.getDate();
      console.log(this.fields.DateDate)
      this.fields.DateTime = now.getHours() + ':' + now.getMinutes();
    },
    submitFunction(){
      if(this.fields.HorizontalOriginDistance < 0){
        this.$store.state.methods.callSnackbar("HorizontalOriginDistance cannot be negative","red")
        return
      }
      if(this.fields.HorizontalDestinationDistance < 0){
        this.$store.state.methods.callSnackbar("HorizontalDestinationDistance cannot be negative","red")
        return
      }
      if(this.fields.VerticalOriginDistance < 0){
        this.$store.state.methods.callSnackbar("VerticalOriginDistance cannot be negative","red")
        return
      }
      if(this.fields.VerticalDestinationDistance < 0){
        this.$store.state.methods.callSnackbar("VerticalDestinationDistance cannot be negative","red")
        return
      }
      if(this.fields.Load < 0){
        this.$store.state.methods.callSnackbar("Load cannot be negative","red")
        return
      }
      if(this.fields.WorkDuration < 0){
        this.$store.state.methods.callSnackbar("WorkDuration cannot be negative","red")
        return
      }
      if(this.fields.Frequency < 0){
        this.$store.state.methods.callSnackbar("Frequency cannot be negative","red")
        return
      }
      if(this.fields.CouplingCondition < 0){
        this.$store.state.methods.callSnackbar("CouplingCondition cannot be negative","red")
        return
      }

      this.$store.dispatch('submitNIOSH')
    },
    clearFunction(){
      this.$store.dispatch('clearNIOSH')
    },
    validateDate(){
      function getLocalDate(DateDate,DateTime){
        var localDateVal = DateDate.split('-');
        var localDate = new Date();
        localDate.setFullYear(localDateVal[0])
        localDate.setMonth(localDateVal[1]-1)
        localDate.setDate(localDateVal[2])
        if(DateTime != null){
          var localTime = DateTime.split(':');
          localDate.setHours(localTime[0],localTime[1]);
        } else {
          localDate.setHours(0,0);
        }
        return localDate;
      }
      let localDateNum = getLocalDate(this.fields.DateDate,this.fields.DateTime).getTime()
      let now = Date.now()
      if (now < localDateNum){
        this.fields.DateDate = null
        this.fields.DateTime = null
        this.$store.state.methods.callSnackbar("Date cannot be future","red")
      }
    },
    updateNioshLi(){

      //Redefining function and variables for python converted code
      let vm, hm, dm, a, b, c, am, fm, cm,
      abs = Math.abs, page = this,
      round = function (val, fixedDecimals){
        return val.toFixed(fixedDecimals)
      },
      math = {
        ceil: Math.ceil
      }
      /**
       *  Calculate a single RWL (either destination or origin)
       */ 
      function calculate_niosh_rwl(horizontal_dist, vertical_dist, travel_dist,
                          asymmetry_angle, frequency, work_duration, coupling_condition){
          //Progoma; {u}

          if(horizontal_dist <= 250){
            hm = 1.0
          }
          else if(horizontal_dist > 630){
            hm = 0
          }
          else{
            hm = 250 / horizontal_dist
          }
                    
          if(vertical_dist > 1750){
            vm = 0
          }
          else{
            vm = 1 - .0003 * abs(vertical_dist - 750)
          }
          
          if(travel_dist <= 250){
            dm = 1
          }
          else if(travel_dist > 1750){
            dm = 0
          }
          else{
            dm = .82 + (4.55 / (travel_dist / 10))
          }
                  
          am = 1 - .0032 * abs(asymmetry_angle)
          
          if(frequency < .2){
            a = 0
          }
          else if(frequency <= .5){
            a = 1
          }
          else if(frequency > 15){
            a = 17
          }
          else{
            a = math.ceil(frequency) + 1
          }
          
          if(work_duration < 1){
            b = 0
          }
          else if(work_duration < 2){
            b = 1
          }
          else{
            b = 2
          }
          
          if(vertical_dist >= 750){
            c = 1
          }
          else{
            c = 0
          }
          console.log(a + " " + b + " " + c )
          fm = page.freq_table[ a ][ b ][ c ]
          
          cm = page.couple_table[ coupling_condition ][ c ]
          
          console.log(hm + " " + vm + " " + dm + " " + am + " " + fm + " " + cm)

          return round(23 * hm * vm * dm * am * fm * cm, 2)
      }
              
          
          
          
      // function calculate_niosh_li(horizontal_dist, vertical_dist, travel_dist,
      //                       asymmetry_angle, frequency, work_duration, 
      //                       coupling_condition, load_weight){
                              
      //                         rwl = calculate_niosh_rwl(horizontal_dist, vertical_dist, travel_dist,
      //                     asymmetry_angle, frequency, work_duration, coupling_condition)
                          
      //     return round(load_weight / rwl, 2)
      // }
      let travelDist =  Math.abs(this.fields.VerticalOriginDistance - this.fields.VerticalDestinationDistance)
      this.fields.RWLOrigin = calculate_niosh_rwl(this.fields.HorizontalOriginDistance, this.fields.VerticalOriginDistance, travelDist,
        this.fields.AsymmetryAngle, this.fields.Frequency, this.fields.WorkDuration,
        this.fields.CouplingCondition)
      
      this.fields.RWLDestination = calculate_niosh_rwl(this.fields.HorizontalDestinationDistance, this.fields.VerticalDestinationDistance, travelDist,
        this.fields.AsymmetryAngle, this.fields.Frequency, this.fields.WorkDuration,
        this.fields.CouplingCondition)
      
      if(this.fields.Load != null && this.fields.Load != 0){
        let loadKg = this.fields.Load/this.conversions.kgToNewtons
        this.fields.LIOrigin = (loadKg/this.fields.RWLOrigin).toFixed(2)
        this.fields.LIDestination = (loadKg/this.fields.RWLDestination).toFixed(2)
      }
    }
  }
}
</script>

<style>

</style>
