import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import store from './store'
import './registerServiceWorker'
import moment from 'moment'

// import VueFormulate from '@braid/vue-formulate'

// Vue.use(VueFormulate)

Vue.config.productionTip = false

new Vue({
  vuetify,
  store,
  // camera,
  render: h => h(App)
}).$mount('#app')

Vue.filter('formatDate', function(value) {
  if (value) {
      return moment(String(value)).format('MM/DD/YYYY h:mma')
  }
});