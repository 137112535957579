var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.common.fields.WorkCenter != null),expression:"common.fields.WorkCenter != null"}]},[_c('v-row',[_c('v-col',[_c('v-combobox',{attrs:{"return-object":"","solo":"","clearable":"","persistent-hint":"","hint":"Available tasks based on selected Work Center","label":"Task Name","items":_vm.lists.Tasks,"item-text":"TaskName","item-value":"url","rules":[
                function (value) { return typeof value != 'string'
                ||  value.length > 0
                || 'Task name must not be empty'; }
            ]},model:{value:(_vm.addTaskName),callback:function ($$v) {_vm.addTaskName=$$v},expression:"addTaskName"}})],1),_c('v-col',[_c('v-combobox',{attrs:{"return-object":"","solo":"","clearable":"","persistent-hint":"","hint":"","label":"Task Description","items":_vm.lists.Tasks,"item-text":"Description","item-value":"url","rules":[
                function (value) { return typeof value != 'string'
                ||  value.length > 0
                || 'Task description must not be empty'; }
            ]},model:{value:(_vm.addTaskDescription),callback:function ($$v) {_vm.addTaskDescription=$$v},expression:"addTaskDescription"}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAddButton),expression:"showAddButton"}],staticStyle:{"max-width":"70px"}},[_c('v-spacer'),_c('v-btn',{attrs:{"elevation":"2","color":"blue","icon":""},on:{"click":_vm.addNewTask}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-spacer')],1)],1)],1),_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],on:{"click":function($event){_vm.UI.triggerFactoryCard = true}}},[_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(_vm.common.fields.WorkCenter == null),expression:"common.fields.WorkCenter == null"}],attrs:{"disabled":"","solo":"","clearable":"","persistent-hint":"","hint":"Available tasks based on selected Work Center","label":"Please select Work Center","background-color":"grey"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }