<template>
  <PageCameraFormTab :title=title :subtitle=subtitle :submitFunction=this.submitFunction :clearFunction=clearFunction :loadAngleFunction=this.loadAngleFunction clearText="New" icon="mdi-human">
    <template v-slot:title><v-text-field label="RULA Score" style="max-width: 65px; max-height: 0px" disabled v-model="fields.RulaScore"/></template>
    <template v-slot:default>
      <select-task-field/>
      <center>
      <!-- 
      <v-text-field clearable v-model="EngineerID" label="EngineerID"/> 
      -->
        <V-tabs fixed-tabs v-model="tab" center-active show-arrows="">
          <v-tabs-slider></v-tabs-slider>
          <v-tab>Info</v-tab>
          <v-tab>Arms/Wrist</v-tab>
          <v-tab>Neck/Trunk/Legs</v-tab>
        </V-tabs>
        <!-- <v-card flat > -->
        <v-tabs-items v-model="tab" style="padding-top:10px">
          <v-tab-item> <!-- Info Tab -->
            <h3>Time and Date of Assessment</h3>
            <v-date-picker @change="validateDate" clearable v-model="fields.DateDate" label="DateDate" class="ma-4"/>
            <v-time-picker @input="validateDate" clearable v-model="fields.DateTime" label="DateTime" class="ma-4"/>
          </v-tab-item>
          <v-tab-item> <!-- Arms/Wrist Tab -->
            <h3 style="padding-top:20px">Upper Arm Angle</h3>
            <v-slider
                thumb-size="35" thumb-label="always" style="padding-top: 50px"                
                @change="updateRulaScore" v-model="fields.UpperArmAngle"
                :max="limits.upperArmAngle_max"
                :min="limits.upperArmAngle_min"
                step="1"
                ><template v-slot:prepend><div class="rangeSlotDiv">Extension<v-icon>mdi-minus</v-icon></div></template>
                    <template v-slot:append><div class="rangeSlotDiv"><v-icon>mdi-plus</v-icon> Flexion<v-text-field class="rangeTextfield" clearable @change="updateRulaScore" v-model="fields.UpperArmAngle" type="number" suffix="°" :rules="[value => !(value > limits.upperArmAngle_max || value < limits.upperArmAngle_min) || 'Has to be between ' + limits.upperArmAngle_max  +' and ' + limits.upperArmAngle_min]"/></div></template>
                </v-slider>
            <v-row>
              <v-col>
                <v-checkbox clearable @change="updateRulaScore" v-model="fields.ShoulderRaised" label="Shoulder Raised"/>
              </v-col>
              <v-col>
                <v-checkbox clearable @change="updateRulaScore" v-model="fields.UpperArmAbducted" label="Upper Arm Abducted"/>
              </v-col>
              <v-col>
                <v-checkbox clearable @change="updateRulaScore" v-model="fields.ArmSupportOrLean" label="Arm Supported Or Leaning"/>
              </v-col>
            </v-row>
            <h3 style="padding-top:20px">Lower Arm Angle</h3>
            <v-slider
                thumb-size="35" thumb-label="always" style="padding-top: 50px"
                @change="updateRulaScore" v-model="fields.LowerArmAngle"
                :max="limits.lowerArmAngle_max"
                :min="limits.lowerArmAngle_min"
                step="1"
                ><template v-slot:prepend><div class="rangeSlotDiv">Extension<v-icon>mdi-minus</v-icon></div></template>
                    <template v-slot:append><div class="rangeSlotDiv"><v-icon>mdi-plus</v-icon> Flexion<v-text-field class="rangeTextfield" clearable @change="updateRulaScore" v-model="fields.LowerArmAngle" type="number" suffix="°" :rules="[value => !(value > limits.lowerArmAngle_max || value < limits.lowerArmAngle_min) || 'Has to be between ' + limits.lowerArmAngle_max  +' and ' + limits.lowerArmAngle_min]"/></div></template>
            </v-slider>
            <v-checkbox clearable @change="updateRulaScore" v-model="fields.ArmAcrossMidline" label="Either arm working across midline or out to side of body"/>
            <h3 style="padding-top:20px">Wrist Arm Angle</h3>
            <v-slider
                thumb-size="35" thumb-label="always" style="padding-top: 50px"
                @change="updateRulaScore" v-model="fields.WristAngle"
                :max="limits.wristAngle_max"
                :min="limits.wristAngle_min"
                step="1"
                ><template v-slot:prepend><div class="rangeSlotDiv">Extension<v-icon>mdi-minus</v-icon></div></template>
                    <template v-slot:append><div class="rangeSlotDiv"><v-icon>mdi-plus</v-icon> Flexion<v-text-field class="rangeTextfield" clearable @change="updateRulaScore" v-model="fields.WristAngle" type="number" suffix="°" :rules="[value => !(value > limits.wristAngle_max || value < limits.wristAngle_min) || 'Has to be between ' + limits.wristAngle_max  +' and ' + limits.wristAngle_min]"/></div></template>
            </v-slider>
            <v-checkbox clearable @change="updateRulaScore" v-model="fields.WristBent" label="Wrist bent from midline"/>
            <v-checkbox clearable @change="updateRulaScore" v-model="fields.WristTwist" label="Wrist twisted near end of range"/>
            <v-checkbox clearable @change="updateRulaScore" v-model="fields.ArmWristMuscleUseScore" label="Posture static >1 minute or repeated actions occurs 4x per minute"/>
            <v-text-field clearable type="number" v-model="fields.ArmWristMuscleForceLoad" @change="updateArmForceNewton" label="Force load" suffix="newtons" :rules="[value=> !(value > 999 || value < 0) || 'everything ok bro?']" />
            <v-text-field clearable type="number" v-model="fields.ArmWristMuscleForceLoadKg" @change="updateArmForceKg" label="Force load" suffix="kilograms"/>
            <v-text-field clearable type="number" v-model="fields.ArmWristMuscleForceLoadLb" @change="updateArmForceLb" label="Force load" suffix="pound-force"/>
            <!-- <v-checkbox @change="updateRulaScore" label="Repeated Or Shock" v-model="fields.ArmRepeatedOrShock"/> -->
            <h3 style="padding-top:20px">Arm/Wrist Muscle Force Type</h3>
            <v-radio-group
             @change="updateRulaScore" v-model="fields.ArmWristMuscleForceLoadType"

             hint=""
             :persistent-hint="showHint"
             row>
             <v-col>
             <v-radio
              label="Load < 2kg (Intermittent)"
              value="0"
              
             />
             <v-radio
              label="Load between 4kg and 9 kg (Static)"
              value="1"
             />
             </v-col>

             <v-col>
             <v-radio
              label="Load between 4kg and 9 kg (Repeated)"
              value="2"
             />
             <v-radio
              label="Load > 9kg (Repeated or with shocks)"
              value="3"
             />
             </v-col>
            </v-radio-group>
          </v-tab-item>
          <v-tab-item> <!-- Neck Tab -->
            <v-row justify="space-around">
              <v-col cols="12" md="6" lg="8">
                <h3 style="padding-top:20px">Neck Angle</h3>
                <v-slider
                  thumb-size="35" thumb-label="always" style="padding-top: 50px"
                  v-model="fields.NeckPositionAngle"
                  :max="limits.neckAngle_max"
                  :min="limits.neckAngle_min"
                  @change="updateRulaScore"
                  step="1"
                ><template v-slot:prepend><div class="rangeSlotDiv">Extension<v-icon>mdi-minus</v-icon></div></template>
                    <template v-slot:append><div class="rangeSlotDiv"><v-icon>mdi-plus</v-icon> Flexion<v-text-field class="rangeTextfield" @change="updateRulaScore" clearable v-model="fields.NeckPositionAngle" type="number" suffix="°"
                    :rules="[
                      value => !(value > limits.neckAngle_max || value < limits.neckAngle_min) || 'Has to be between ' + limits.neckAngle_max  +' and ' + limits.neckAngle_min,
                    ]"/></div>
                    </template>
                </v-slider>
              </v-col>
              <v-col><v-checkbox @change="updateRulaScore" clearable v-model="fields.NeckTwist" :rules="[ruleNeckTwistSideBend]" label="Neck Twist"/></v-col>
              <v-col><v-checkbox @change="updateRulaScore" clearable v-model="fields.NeckSideBend" :rules="[ruleNeckTwistSideBend]" label="Neck Sidebend"/></v-col>
            </v-row>
          
            <v-row>
              <v-col cols="12" md="6" lg="8">
                <h3 style="padding-top:20px">Trunk Angle</h3>
                <v-slider
                  thumb-size="35" thumb-label="always" style="padding-top: 50px"
                  v-model="fields.TrunkAngle"
                  @change="updateRulaScore" 
                  :max="limits.trunkAngle_max"
                  :min="limits.trunkAngle_min"
                  step="1"
                ><template v-slot:prepend><div class="rangeSlotDiv">Extension<v-icon>mdi-minus</v-icon></div></template>
                    <template v-slot:append><div class="rangeSlotDiv"><v-icon>mdi-plus</v-icon> Flexion<v-text-field @change="updateRulaScore" class="rangeTextfield" clearable v-model="fields.TrunkAngle" type="number" suffix="°" :rules="[value => !(value > limits.trunkAngle_max || value < limits.trunkAngle_min) || 'Has to be between ' + limits.trunkAngle_max  +' and ' + limits.trunkAngle_min]"/></div></template>
                </v-slider>
              </v-col>
              <v-col><v-checkbox clearable @change="updateRulaScore" v-model="fields.TrunkTwist" :rules="[ruleTrunkTwistSideBend]" label="Trunk Twist"/></v-col>
              <v-col><v-checkbox clearable @change="updateRulaScore" v-model="fields.TrunkSideBend" :rules="[ruleTrunkTwistSideBend]" label="Trunk Sidebend"/></v-col>
            </v-row>
          
            <v-checkbox @change="updateRulaScore" label="Both legs and feet are supported" v-model="fields.LegSupported"/>
            <v-checkbox clearable @change="updateRulaScore" v-model="fields.NeckTrunkLegMuscleUseScore" label="Posture static >1 minute or repeated actions occurs 4x per minute"/>
            <v-text-field clearable type="number" v-model="fields.NeckTrunkLegForceLoad" @change="updateLegForceNewton" label="Force load" suffix="newtons" :rules="[value=> !(value > 999 || value < 0) || 'everything ok bro?']" />
            <v-text-field clearable type="number" v-model="fields.NeckTrunkLegForceLoadKg" @change="updateLegForceKg" label="Force load" suffix="kilograms"/>
            <v-text-field clearable type="number" v-model="fields.NeckTrunkLegForceLoadLb" @change="updateLegForceLb" label="Force load" suffix="pound-force"/>

          </v-tab-item>
        </v-tabs-items>
        <!-- </v-card> -->
        <v-row>
          <v-col>
            <v-checkbox v-model="showHint" label="Show hint"/>
          </v-col>
        </v-row>
        <!-- <div id="responseDebug">{{responseDebug}}</div> -->

      </center>
    </template>
    <!-- <slot name="head"></slot> -->
  </PageCameraFormTab>
</template>

<script>
import PageCameraFormTab from './PageCameraFormTab'
import SelectTaskField from './SelectTaskField.vue'
// var straightLegImage = require('../assets/straightleg.png');
// var bentLegImage = require('../assets/onelegbent.png');
export default {
  data: ()=>({
      title: "RULA Form",
      subtitle: "RULA Entire Body Assessment Form",
      tab: null,
      straightLegImage: require('../assets/straightleg.png'),
      bentLegImage: require('../assets/onelegbent.png'),
      showHint: false,
      rulaScoreHint: "",
  }),
  computed: {
    cameraCard: {
      get() {
        return this.$store.state.cameraCard
      }
    },
    common: {
      get() {
        return this.$store.state.forms.Common
      }
    },
    lists: {
      get(){
        return this.$store.state.forms.Lists
      },
    },
    responseDebug: {
      get(){
        return this.$store.state.UI.responseDebug
      },
      set(value){
        this.$store.dispatch('updateREBA', value)
      }
    },
    limits: {
        get() {
          return this.$store.state.limits
        }
      },
    fields: {
      get(){
        return this.$store.state.forms.RULA.fields
      },
      set(value){
        this.$store.dispatch('updateRULA', value)
      }
    },
    conversions: {
      get(){
        return this.$store.state.conversions
      }
    }
  },
  components: {
    PageCameraFormTab,
    SelectTaskField
  },
  mounted: function() {
    this.setNowAsDateAndTime();
  }
  ,
  methods: {
    loadAngleFunction(){
      function getAngle(AngleObj, faceLeft) {
        let angle = Math.abs(AngleObj.lines[0].theta() - AngleObj.lines[1].theta())*(180/Math.PI)
      
        if (((AngleObj.nodes[0].x - AngleObj.nodes[1].x < 0 && AngleObj.nodes[2].x - AngleObj.nodes[1].x < 0)
            || (AngleObj.nodes[0].x - AngleObj.nodes[1].x > 0 && AngleObj.nodes[2].x - AngleObj.nodes[1].x > 0))
            ^ AngleObj.reverseAngle){
              angle = 180 - angle
            }

        if (AngleObj.nodes[0].isBelowLine(AngleObj.nodes[1], AngleObj.lines[1]) ^ AngleObj.lines[1].slope() < 0 ^ faceLeft)
        {
          angle = angle * -1
        }

        if (AngleObj.invertAngle){
          angle = angle * -1
        }

        return angle
      }
      let NeckPositionAngle = getAngle(this.$store.state.cameraCard[this.title]["dict"]["neckAngle"],this.$store.state.cameraCard[this.title]["faceLeft"]).toFixed(0)
      let TrunkAngle = getAngle(this.$store.state.cameraCard[this.title]["dict"]["trunkAngle"],this.$store.state.cameraCard[this.title]["faceLeft"]).toFixed(0)
      let UpperArmAngle = getAngle(this.$store.state.cameraCard[this.title]["dict"]["upperArmAngle"],this.$store.state.cameraCard[this.title]["faceLeft"]).toFixed(0)
      let LowerArmAngle = getAngle(this.$store.state.cameraCard[this.title]["dict"]["lowerArmAngle"],this.$store.state.cameraCard[this.title]["faceLeft"]).toFixed(0)
      let WristAngle = getAngle(this.$store.state.cameraCard[this.title]["dict"]["wristAngle"],this.$store.state.cameraCard[this.title]["faceLeft"]).toFixed(0)
      if(NeckPositionAngle < this.limits.neckAngle_min || NeckPositionAngle > this.limits.neckAngle_max){
        this.$store.state.methods.callSnackbar("Please adjust NeckPositionAngle","red")
        return
      }
      if(TrunkAngle < this.limits.trunkAngle_min || TrunkAngle > this.limits.trunkAngle_max){
        this.$store.state.methods.callSnackbar("Please adjust TrunkAngle","red")
        return
      }
      if(UpperArmAngle < this.limits.upperArmAngle_min || UpperArmAngle > this.limits.upperArmAngle_max){
        this.$store.state.methods.callSnackbar("Please adjust UpperArmAngle","red")
        return
      }
      if(LowerArmAngle < this.limits.lowerArmAngle_min || LowerArmAngle > this.limits.lowerArmAngle_max){
        this.$store.state.methods.callSnackbar("Please adjust LowerArmAngle","red")
        return
      }
      if(WristAngle < this.limits.wristAngle_min || WristAngle > this.limits.wristAngle_max){
        this.$store.state.methods.callSnackbar("Please adjust WristAngle","red")
        return
      }
      this.fields.NeckPositionAngle = NeckPositionAngle
      this.fields.TrunkAngle = TrunkAngle
      this.fields.UpperArmAngle = UpperArmAngle
      this.fields.LowerArmAngle = LowerArmAngle
      this.fields.WristAngle = WristAngle
      this.updateRulaScore()
    },
    updateLegForceKg(){
      this.fields.NeckTrunkLegForceLoad = this.fields.NeckTrunkLegForceLoadKg*this.conversions.kgToNewtons
      this.updateLegForceNewton()
    },
    updateLegForceLb(){
      this.fields.NeckTrunkLegForceLoad = this.fields.NeckTrunkLegForceLoadLb*this.conversions.lbToNewtons
      this.updateLegForceNewton()
    },
    updateLegForceNewton(){
      this.fields.NeckTrunkLegForceLoadKg = this.fields.NeckTrunkLegForceLoad/this.conversions.kgToNewtons
      this.fields.NeckTrunkLegForceLoadLb = this.fields.NeckTrunkLegForceLoad/this.conversions.lbToNewtons
      this.updateRulaScore();
    },
    updateArmForceKg(){
      this.fields.ArmWristMuscleForceLoad = this.fields.ArmWristMuscleForceLoadKg*this.conversions.kgToNewtons
      this.updateArmForceNewton()
    },
    updateArmForceLb(){
      this.fields.ArmWristMuscleForceLoad = this.fields.ArmWristMuscleForceLoadLb*this.conversions.lbToNewtons
      this.updateArmForceNewton()
    },
    updateArmForceNewton(){
      this.fields.ArmWristMuscleForceLoadKg = this.fields.ArmWristMuscleForceLoad/this.conversions.kgToNewtons
      this.fields.ArmWristMuscleForceLoadLb = this.fields.ArmWristMuscleForceLoad/this.conversions.lbToNewtons
      this.updateRulaScore();
    },
    ruleNeckTwistSideBend(){
      return (!this.NeckTwist || !this.NeckSideBend) || 'cannot be sidebend and twist'
    },
    ruleTrunkTwistSideBend(){
      return (!this.TrunkTwist || !this.TrunkSideBend) || 'cannot be sidebend and twist'
    },
    setNowAsDateAndTime(){
      var now = new Date();
      this.fields.DateDate = now.getFullYear() + '-' + (now.getMonth()+1) + '-' +  now.getDate();
      console.log(this.DateDate)
      this.fields.DateTime = now.getHours() + ':' + now.getMinutes();
    },
    submitFunction(){
      this.$store.dispatch('submitRULA')
    },
    clearFunction(){
      this.$store.dispatch('clearRULA')
    },
    validateDate(){
      function getLocalDate(DateDate,DateTime){
        var localDateVal = DateDate.split('-');
        var localDate = new Date();
        localDate.setFullYear(localDateVal[0])
        localDate.setMonth(localDateVal[1]-1)
        localDate.setDate(localDateVal[2])
        if(DateTime != null){
          var localTime = DateTime.split(':');
          localDate.setHours(localTime[0],localTime[1]);
        } else {
          localDate.setHours(0,0);
        }
        return localDate;
      }
      let localDateNum = getLocalDate(this.fields.DateDate,this.fields.DateTime).getTime()
      let now = Date.now()
      if (now < localDateNum){
        this.fields.DateDate = null
        this.fields.DateTime = null
        this.$store.state.methods.callSnackbar("Date cannot be future","red")
      }
    },
    updateActivityScore(){
      // this.ActivityScore = 0;
      // if(this.ActivityHeldLong) this.ActivityScore += 1;
      // if(this.ActivitySmallRangeActions) this.ActivityScore += 1;
      // if(this.ActivityRapidLargeRange) this.ActivityScore += 1;
      // console.log(this.ActivityScore);
      
      this.fields.ActivityScore = this.fields.ActivityHeldLong+this.fields.ActivitySmallRangeActions+this.fields.ActivityRapidLargeRange
      this.updateRulaScore();
    },
    updateRulaScore(){
      this.rulaScoreHint = ""
        + " UpperArmAngle:" + this.fields.UpperArmAngle
        + " ShoulderRaised:" + this.fields.ShoulderRaised
        + " UpperArmAbducted:" + this.fields.UpperArmAbducted
        + " ArmSupportOrLean:" + this.fields.ArmSupportOrLean
        + " LowerArmAngle:" + this.fields.LowerArmAngle
        + " ArmAcrossMidline:" + this.fields.ArmAcrossMidline
        + " WristAngle:" + this.fields.WristAngle
        + " WristBent:" + this.fields.WristBent
        + " WristTwist:" + this.fields.WristTwist
        + " NeckPositionAngle:" + this.fields.NeckPositionAngle
        + " NeckTwist:" + this.fields.NeckTwist
        + " NeckSideBend:" + this.fields.NeckSideBend
        + " TrunkAngle:" + this.fields.TrunkAngle
        + " TrunkTwist:" + this.fields.TrunkTwist
        + " TrunkSideBend:" + this.fields.TrunkSideBend
        + " ArmWristMuscleUseScore:" + this.fields.ArmWristMuscleUseScore
        + " NeckTrunkLegMuscleUseScore:" + this.fields.NeckTrunkLegMuscleUseScore
        + " LegSupported:" + this.fields.LegSupported
        + " LegGeometry:" + this.fields.LegGeometry
        + " NeckTrunkLegForceLoad:" + this.fields.NeckTrunkLegForceLoad
        + " NeckTrunkLegForceLoadKg:" + this.fields.NeckTrunkLegForceLoadKg
        + " NeckTrunkLegForceLoadLb:" + this.fields.NeckTrunkLegForceLoadLb
        + " ArmWristMuscleForceLoad:" + this.fields.ArmWristMuscleForceLoad
        + " ArmWristMuscleForceLoadKg:" + this.fields.ArmWristMuscleForceLoadKg
        + " ArmWristMuscleForceLoadLb:" + this.fields.ArmWristMuscleForceLoadLb
        + " RepeatedOrShock:" + this.fields.RepeatedOrShock
      // let pageStore = this.$store.state.forms.RULA.calc
      // Step 1. Neck Position
      let table_a = [[[[1, 2], [2, 2], [2, 3], [3, 3]], [[2, 2], [2, 2], [3, 3], [3, 3]], [[2, 3], [3, 3], [3, 3], [4, 4]]],
           [[[2, 3], [3, 3], [3, 4], [4, 4]], [[3, 3], [3, 3], [3, 4], [4, 4]], [[3, 4], [4, 4], [4, 4], [5, 5]]],
           [[[3, 3], [4, 4], [4, 4], [5, 5]], [[3, 4], [4, 4], [4, 4], [5, 5]], [[4, 4], [4, 4], [4, 5], [5, 5]]],
           [[[4, 4], [4, 4], [4, 5], [5, 5]], [[4, 4], [4, 4], [4, 5], [5, 5]], [[4, 4], [4, 5], [5, 5], [6, 6]]],
           [[[5, 5], [5, 5], [5, 6], [6, 7]], [[5, 6], [6, 6], [6, 7], [7, 7]], [[6, 6], [6, 7], [7, 7], [7, 8]]],
                  [[[7, 7], [7, 7], [7, 8], [8, 9]], [[8, 8], [8, 8], [8, 9], [9, 9]], [[9, 9], [9, 9], [9, 9], [9, 9]]]]

      let table_b = [[[1, 3], [2, 3], [3, 4], [5, 5], [6, 6], [7, 7]],
           [[2, 3], [2, 3], [4, 5], [5, 5], [6, 7], [7, 7]],
           [[3, 3], [3, 4], [4, 5], [5, 6], [6, 7], [7, 7]],
           [[5, 5], [5, 6], [6, 7], [7, 7], [7, 7], [8, 8]],
           [[7, 7], [7, 7], [7, 8], [8, 8], [8, 8], [8, 8]],
                  [[8, 8], [8, 8], [8, 8], [8, 9], [9, 9], [9, 9]]]

      let table_c = [[1, 2, 3, 3, 4, 5, 5],
           [2, 2, 3, 4, 4, 5, 5],
           [3, 3, 3, 4, 4, 5, 6],
           [3, 3, 3, 4, 5, 6, 6],
           [4, 4, 4, 5, 6, 7, 7],
           [4, 4, 5, 6, 6, 7, 7],
           [5, 5, 6, 6, 7, 7, 7],
                  [5, 5, 6, 7, 7, 7, 7]]

      let force_load_table = [[0, 1, 2, 3],
                    [1, 2, 2, 3],
                          [3, 3, 3, 3]]
      let a,b,c = null
      function score_a(upper_arm, lower_arm, wrist_score, wrist_twist){
    return table_a[upper_arm - 1][lower_arm - 1][wrist_score - 1][wrist_twist - 1]
      }

      function score_b(neck_posture, trunk_posture, legs){
    return table_b[neck_posture - 1][trunk_posture - 1][legs - 1]
      }

      function score_c(wrist_arm, neck_trunk_leg){
        a = Math.min(wrist_arm, 8)
        b = Math.min(neck_trunk_leg, 7)
        return table_c[a - 1][b - 1]
      }

      function calculate_rula(neck_angle, neck_twist, neck_side_bend,
                        trunk_angle, trunk_twist, trunk_side_bend,
                        upper_arm_angle, shoulder_raised, upper_arm_abducted, arm_support_or_lean,
                        lower_arm_angle, lower_arm_midline_or_side,
                        wrist_angle, wrist_bent, wrist_twist, 
                        arm_wrist_muscle_use, arm_wrist_force_load, arm_wrist_force_type,
                        leg_support,
                        neck_trunk_leg_muscle_use, neck_trunk_leg_force_load, neck_trunk_leg_force_type){
        let neck_trunk_leg_force_load_score, arm_wrist_force_load_score, neck_score, trunk_score, upper_arm_score, lower_arm_score, wrist_score, leg_support_score = null;
        if (upper_arm_angle <= -20){
            upper_arm_score = 2 + shoulder_raised + upper_arm_abducted - arm_support_or_lean
        } else if (upper_arm_angle < 20){
            upper_arm_score = 1 + shoulder_raised + upper_arm_abducted - arm_support_or_lean
        } else if (upper_arm_angle < 45){
            upper_arm_score = 2 + shoulder_raised + upper_arm_abducted - arm_support_or_lean
        } else if (upper_arm_angle < 90){
            upper_arm_score = 3 + shoulder_raised + upper_arm_abducted - arm_support_or_lean
        } else {
            upper_arm_score = 4 + shoulder_raised + upper_arm_abducted - arm_support_or_lean
        }
        if(upper_arm_score == 0)
          upper_arm_score = 1
        if (lower_arm_angle <= 60 || lower_arm_angle >= 100){
            lower_arm_score = 2 + lower_arm_midline_or_side
        } else {
            lower_arm_score = 1 + lower_arm_midline_or_side
        }
        if (wrist_angle <= -15 || wrist_angle >= 15){
            wrist_score = 3 + wrist_bent
        } else if (wrist_angle != 0){
            wrist_score = 2 + wrist_bent
        } else {
            wrist_score = 1 + wrist_bent
        }   
        //  converted 4.4 lbs and 22 lbs to kg
        if (arm_wrist_force_load < 1.99581){
            arm_wrist_force_load_score = force_load_table[0][arm_wrist_force_type]
        } else if (arm_wrist_force_load < 9.97903){
            arm_wrist_force_load_score = force_load_table[1][arm_wrist_force_type]
        } else {
            arm_wrist_force_load_score = force_load_table[2][arm_wrist_force_type]
        }
        if (neck_angle >= 20){
            neck_score = 3 + neck_twist + neck_side_bend
        } else if (neck_angle >= 10){
            neck_score = 2 + neck_twist + neck_side_bend
        } else if (neck_angle >= 0){
            neck_score = 1 + neck_twist + neck_side_bend
        } else {
            neck_score = 4 + neck_twist + neck_side_bend
        }   
        if (trunk_angle >= 60){
            trunk_score = 4 + trunk_twist + trunk_side_bend
        } else if (trunk_angle >= 20){
            trunk_score = 3 + trunk_twist + trunk_side_bend
        } else if (trunk_angle != 0){
            trunk_score = 2 + trunk_twist + trunk_side_bend
        } else {
            trunk_score = 1 + trunk_twist + trunk_side_bend
        }
        if (leg_support){
            leg_support_score = 1
        } else {
            leg_support_score = 2
        }   
        if (neck_trunk_leg_force_load < 1.99581){
            neck_trunk_leg_force_load_score = force_load_table[0][neck_trunk_leg_force_type]
        } else if (neck_trunk_leg_force_load < 9.97903){
            neck_trunk_leg_force_load_score = force_load_table[1][neck_trunk_leg_force_type]
        } else {
            neck_trunk_leg_force_load_score = force_load_table[2][neck_trunk_leg_force_type]
        }
        
        a = score_a(upper_arm_score, lower_arm_score, wrist_score, wrist_twist + 1)
        b = score_b(neck_score, trunk_score, leg_support_score)
        c = score_c(a + arm_wrist_muscle_use + arm_wrist_force_load_score, b + neck_trunk_leg_muscle_use + neck_trunk_leg_force_load_score)
        return c
      }
      let fields = this.fields

      console.log([fields.NeckPositionAngle, fields.NeckTwist, fields.NeckSideBend,
                        fields.TrunkAngle, fields.TrunkTwist, fields.TrunkSideBend,
                        fields.UpperArmAngle, fields.ShoulderRaised, fields.UpperArmAbducted, fields.ArmSupportOrLean,
                        fields.LowerArmAngle, fields.ArmAcrossMidline,
                        fields.WristAngle, fields.WristBent, fields.WristTwist, 
                        fields.ArmWristMuscleUseScore, fields.ArmWristMuscleForceLoad, fields.ArmWristMuscleForceLoadType,
                        fields.LegSupported,
                        fields.NeckTrunkLegMuscleUseScore, fields.NeckTrunkLegForceLoad, fields.NeckTrunkLegForceType])
      this.fields.RulaScore = calculate_rula(fields.NeckPositionAngle, fields.NeckTwist, fields.NeckSideBend,
                        fields.TrunkAngle, fields.TrunkTwist, fields.TrunkSideBend,
                        fields.UpperArmAngle, fields.ShoulderRaised, fields.UpperArmAbducted, fields.ArmSupportOrLean,
                        fields.LowerArmAngle, fields.ArmAcrossMidline,
                        fields.WristAngle, fields.WristBent, fields.WristTwist, 
                        fields.ArmWristMuscleUseScore, fields.ArmWristMuscleForceLoadKg, fields.ArmWristMuscleForceLoadType,
                        fields.LegSupported,
                        fields.NeckTrunkLegMuscleUseScore, fields.NeckTrunkLegForceLoadKg, fields.NeckTrunkLegForceType)
    }
  }
}
</script>

<style>
.rangeTextfield {
  min-width: 80px;
  margin-left: 5px;
}
.rangeSlotDiv {
  max-height: 24px;
  display: flex;
  align-items: center;
}
</style>