<template>
  <v-app>
    <v-app-bar  app dark dense collapse collapse-on-scroll fade-img-on-scroll elevate-on-scroll :color=appBarColor :min-width="!this.online*75 + 120">
      <v-app-bar-title>
        Ergonomics Assistant
      </v-app-bar-title>
      <v-spacer/>
        <v-btn icon @click="switchTheme()">
          <v-icon v-show="!this.$vuetify.theme.dark">mdi-lightbulb-on</v-icon>
          <v-icon v-show="this.$vuetify.theme.dark">mdi-lightbulb-off</v-icon>
        </v-btn>
        <v-alert dense color="error" v-show="!this.online" min-width="75">Offline</v-alert>
        <v-btn icon @click="updateOnline()">
          <v-icon :color=connectionStatusColor v-show="this.online">mdi-wifi</v-icon>
          <v-icon :color=connectionStatusColor v-show="!this.online">mdi-wifi-strength-off</v-icon>
        </v-btn>
        <template v-slot:extension><v-tabs v-model="tab" align-with-title><v-tabs-slider color="secondary"></v-tabs-slider>
            <v-tab @click="showFactoryInfoForm = false" v-for="item in items" :key="item">{{ item }}</v-tab>
            <v-spacer/>
            <v-dialog v-model="dialog">
              <template v-slot:activator="{on,attrs}">
                <v-btn icon v-on="on" v-bind="attrs"><v-icon>mdi-bomb</v-icon></v-btn>
              </template>
              <v-card>
                <v-card-title>
                  Are you sure?
                </v-card-title>
                <v-card-text>
                  If you want to proceed with deleting all your entered data, hit the bomb icon below.<br/>
                  Otherwise, please tap areas outside this card to close this dialog
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn @click="clearAll">Clear All<v-icon>mdi-bomb</v-icon></v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-btn icon @click="showFactoryInfoForm = !showFactoryInfoForm"><v-icon>mdi-factory</v-icon></v-btn>
            <!-- <v-btn icon @click="showPersonnelInfoForm = !showPersonnelInfoForm"><v-icon>mdi-face</v-icon></v-btn> -->
          </v-tabs></template>
    </v-app-bar>
    <v-main app>
        <v-container fluid>
            <!-- <v-row dense v-show="false"> -->
          <v-row dense>
            <v-expand-transition>
              <v-col cols="12" sm="4" v-show="showFactoryInfoForm"><FactoryInfoFormCard/></v-col>
            </v-expand-transition>
            <v-expand-transition>
              <v-col cols="12" sm="4" v-show="showFactoryInfoForm"><engineer-info-form-card/></v-col>
            </v-expand-transition>
            <v-expand-transition>
              <v-col cols="12" sm="4" v-show="showFactoryInfoForm"><worker-info-form-card/></v-col>
            </v-expand-transition>
            <v-col cols="12">
              <v-tabs-items v-model="tab" style="overflow: visible; background: none;" touchless>
                  <RebaTab/>
                  <RulaTab/>
                  <NioshTab/>
                  <history-tab/>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-container>
    <v-snackbar v-model="UI.snackbar" :color=UI.snackbarColor>{{ UI.snackbarText }}<template v-slot:action="{ attrs }"><v-btn text v-bind="attrs" @click="UI.snackbar = false">Close</v-btn></template></v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import RebaTab from './components/RebaTab'
import RulaTab from './components/RulaTab'
import NioshTab from './components/NioshTab'
import FactoryInfoFormCard from './components/FactoryInfoFormCard.vue'
import EngineerInfoFormCard from './components/EngineerInfoFormCard.vue'
import WorkerInfoFormCard from './components/WorkerInfoFormCard.vue'
import HistoryTab from './components/HistoryTab'


var toolbarImage = require('./assets/banner.jpg');

export default {
  name: 'App',

  components: {
    RebaTab,
    RulaTab,
    NioshTab,
    FactoryInfoFormCard,
    EngineerInfoFormCard,
    WorkerInfoFormCard,
    HistoryTab,
  },

  data: () => ({
    items: ['REBA','RULA','NIOSH','History'], //tab names to populate
    snackbarOnlineColor: "green",
    snackbarOfflineColor: "error",
    appBarOnlineColor: "primary",
    appBarOfflineColor: "darkgray",
    snackbarColor: null,
    appBarColor: "null",
    online: true, //online status
    connectionStatusColor: "green",
    toolbarImageMounted: toolbarImage,
    dialog: false,
    
  }),
  computed: {
    showFactoryInfoForm: {
      get(){
        return this.UI.triggerFactoryCard
      },
      set(val){
        this.UI.triggerFactoryCard = val
      }
    },
    tab: {
      get(){
        return this.UI.appTab
      },
      set(val){
        this.UI.appTab = val
      }
    },
    UI: {
      get(){
        return this.$store.state.UI
      },
      set(val){
        this.$store.dispatch('updateUI', val)
      }
    }, //the popup that comes up when you click on the wifi icon
  },
  methods: {
    callSnackbar(text, color){
      console.log("snackbar called: " + text)
      this.$store.state.UI.snackbar = true;
      this.$store.state.UI.snackbarText = text;
      this.$store.state.UI.snackbarColor = color;
      return
    },
    showConnectionStatus(){
      if(this.online) this.callSnackbar("You're online",this.snackbarOnlineColor);
      else this.callSnackbar("You're offline", this.snackbarOfflineColor);
    },
    updateOnline(){
      this.online = navigator.onLine;
      if(this.online){
        console.log('online');
        this.connectionStatusColor = this.snackbarOnlineColor;
        this.appBarColor = this.appBarOnlineColor;
        this.toolbarImageMounted = toolbarImage;
      } else {
        console.log("offline");
        this.connectionStatusColor = this.snackbarOfflineColor;
        this.appBarColor = this.appBarOfflineColor;
        this.toolbarImageMounted = null;
      }
      this.showConnectionStatus();
    },
    clearAll(){
      this.dialog = false
      this.$store.dispatch('clearAll')
    },
    switchTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
  },
  mounted: function(){
      console.log(this.$store);
      console.log(this.callSnackbar)
      console.log("loaded");
      window.addEventListener('online', this.updateOnline);
      window.addEventListener('offline', this.updateOnline);
      [
        'loadFactoryList',
        'loadEngineerList',
        'loadWorkerList',
      ].forEach(this.$store.dispatch)
      this.$store.dispatch('setCallSnackbar',this.callSnackbar)
      console.log("logging snackbar")
      console.log(this.callSnackbar)
      console.log(this.$store.state.methods.callSnackbar)
      // function dispatcher(item)
        

      this.updateOnline();
  },
};
</script>

<style scoped>

</style>


// [ ] Import Picture
  // [ ] Drawing on picture
// [ ] Data Validation front-end
// [ ] Backend connection
