import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light:
            {
            primary: '#367C2B',
            secondary: '#8bc34a',
            accent: '#607d8b',
            error: '#f44336',
            warning: '#795548',
            info: '#00bcd4',
            success: '#4caf50'
            },
            dark:
            {
            primary: '#ffc107',
            secondary: '#8bc34a',
            accent: '#607d8b',
            error: '#f44336',
            warning: '#795548',
            info: '#00bcd4',
            success: '#4caf50'
            },
        }
    },

});
