<template>
  <form-card title="Factory Info" :subtitle=subtitle :clearFunction=clearForm icon="mdi-factory" :submitFunction=triggerFactoryCard saveText="Close" :showSave=true>
  <v-combobox label="FactoryDescription" clearable :items="lists.Factory" item-text="Description" item-value="url" v-model="fields.FactoryName" v-on:change="getFactorySelection" :rules="selectInList"></v-combobox>
  <v-combobox label="FactoryName" clearable :items="lists.Factory" item-text="FactoryName" item-value="url" v-model="fields.FactoryName" v-on:change="getFactorySelection" :rules="selectInList"></v-combobox>

  <v-combobox label="CITeamDescription (optional)" clearable :items="lists.CITeam" item-text="CITeamName" item-value="url" v-model="fields.CITeam" v-show="fields.FactoryName!=null" :rules="selectInList"></v-combobox>
  <v-combobox label="CITeamUnitCode (optional)" clearable :items="lists.CITeam" item-text="UnitCode" item-value="url" v-model="fields.CITeam" v-show="fields.FactoryName!=null" :rules="selectInList"></v-combobox>

  <v-combobox label="BusinessUnitDescription" clearable :items="lists.BusinessUnit" item-text="Description" item-value="url" v-model="fields.BusinessUnit" v-on:change="getBusinessUnitSelection" v-show="fields.FactoryName!=null" :rules="selectInList"></v-combobox>
  <v-combobox label="BusinessUnitName" clearable :items="lists.BusinessUnit" item-text="BusinessUnitName" item-value="url" v-model="fields.BusinessUnit" v-on:change="getBusinessUnitSelection" v-show="fields.FactoryName!=null" :rules="selectInList"></v-combobox>

  <v-combobox label="DepartmentDescription" clearable :items="lists.Department" item-text="Description" item-value="url" v-model="fields.Department" v-on:change="getDepartmentSelection" v-show="fields.BusinessUnit!=null" :rules="selectInList"></v-combobox>
  <v-combobox label="DepartmentName" clearable :items="lists.Department" item-text="DeptName" item-value="url" v-model="fields.Department" v-on:change="getDepartmentSelection" v-show="fields.BusinessUnit!=null" :rules="selectInList"></v-combobox>


  <v-combobox label="WorkCenterDescription" clearable :items="lists.WorkCenter" item-text="Description" item-value="url" v-model="fields.WorkCenter" @change="getWorkCenterSelection" v-show="fields.Department!=null" :rules="selectInList"></v-combobox>
  <v-combobox label="WorkCenterName" clearable :items="lists.WorkCenter" item-text="WorkCenterName" item-value="url" v-model="fields.WorkCenter" @change="getWorkCenterSelection" v-show="fields.Department!=null" :rules="selectInList"></v-combobox>
  

  </form-card>
</template>

<script>
import FormCard from '../components/FormCard'
export default {
  data: () => ({
    selectInList: [
                    value => typeof value == 'object'
                    || 'Must be an item from the list'
                ],
    subtitle:"Factory Hierarchy Form"
  }),
  computed: {
    fields: {
      get(){
        return this.$store.state.forms.Common.fields
      },
      set(value){
        this.$store.dispatch('updateCommon', value)
      }
    },
    lists: {
      get(){
        return this.$store.state.forms.Lists
      }
    }
  },
  components: {
      FormCard,
  },
  methods: {
    clearForm(){
      this.$store.dispatch('clearFactory')
    },
    log(){
      console.log(this.Factory);
    },
    getFactorySelection(){
      console.log(this)
      this.fields.CITeam = null
      this.fields.BusinessUnit = null
      if (this.fields.FactoryName == null){
        this.getBusinessUnitSelection()
      } else
        ['loadCITeamList','loadBusinessUnitList',].forEach(this.$store.dispatch)
    },
    getBusinessUnitSelection(){
      this.fields.Department = null
      if (this.fields.BusinessUnit == null) {
        this.getDepartmentSelection()
      } else
        this.$store.dispatch('loadDepartmentList')
    },
    getDepartmentSelection(){
      this.fields.WorkCenter = null
      if (this.fields.Department == null) {
        this.getWorkCenterSelection()
      } else
        this.$store.dispatch('loadWorkCenterList')
    },
    getWorkCenterSelection(){
      if(this.fields.WorkCenter)
        this.$store.dispatch('loadTasksList')
    },
    triggerFactoryCard(){
      this.$store.dispatch('triggerFactoryCard')
    },

  },
  mounted: ()=>{
    console.log("factoryinfoformmounted")
  },
  activated: ()=>{
  }
}
</script>

<style>

</style>